import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import StoreX from '../../redux/oldStore';
import { ServerResponse } from '../../utils/Server';
import { v4 as uid } from 'uuid';

import Modal from '../Modal/Modal';

import Icon, { IconType } from '../Icon/Icon';
import Editor from './Editor';

import { IFormDetail, IFormGroup, ISignerInfo, IWizardQuestion } from './formTypes';
import FormBuilder from './FormBuilder';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Selectable } from '../../utils/CommonTypes/CommonTypes';
import FormsWizardBuilder from './FormsWizardBuilder';
import { IListItem } from '../_Core/CoreTypes';

import Select from "react-select";

interface IPacketBuilderProps {
  packet: IFormGroup;
  divisions: IListItem[];
  categories: IListItem[];
  entryTypes: IListItem[];
  update: Function;
  close: Function;
}

const PacketBuilder = (props: IPacketBuilderProps) => {
  const [packet, setPacket] = useState<IFormGroup>(props.packet);
  const [selectedForm, setSelectedForm] = useState<IFormDetail>();
  const [selectedSigner, setSelectedSigner] = useState<ISignerInfo>();
  const [showSignModal, setShowSignModal] = useState<boolean>();

  useEffect(() => {
    if (selectedSigner) {
      setShowSignModal(true);
    }
  }, [selectedSigner]);

  const save = (close?: boolean) => {
    let p = packet;
    StoreX.instance.server.postApi<ServerResponse<IFormGroup>>(`../GenericForms/SavePacket`, p).then((x) => {
      if (x.Success) {
        setPacket(x.Value);
        props.update(x.Value);
        if (close === true) setTimeout(props.close, 500);
      } else {
        toast.error(`Error saving packet. ${x.Message}`, {
          autoClose: false,
        });
      }
    });
  };

  const saveAndClose = () => {
    save(true);
  };

  const blankForm = () => {
    let x: IFormDetail = {
      PacketPublicId: packet.PublicId,
      Description: '',
      Details: {},
      Name: '',
      PublicId: uid(),
      Fields: [],
      FilePath: '',
      UsedBasedOnWizard: false,
      FilePerOwner: false,
      Id: 0,
      NewFilePath: '',
      Rules: [],
      SupportingDocuments: [],
      Comments: []
    };
    return x;
  };

  const formUpdated = (form: IFormDetail, signers2: Selectable[]) => {
    setSelectedForm({ ...form });
    let ps = packet.Forms ?? [];
    let found = false;
    for (let i = 0; i < ps.length; i++) {
      if (ps[i].PublicId === form.PublicId) {
        ps[i] = form;
        found = true;
        break;
      }
    }
    if (!found) ps.push(form);

    //add missing signers
    let eSigners = [...(packet.Signers ?? [])];
    let missingSigners = signers2.filter((x) => !eSigners.find((y) => y.PublicId === x.value));
    missingSigners.forEach((s) => {
      eSigners.push({
        Name: s.label,
        PublicId: s.value + '',
        Description: '',
        Instructions: '',
        IsSelf: false,
      });
    });
    

    var newPacket = { ...packet, Forms: [...ps], Signers: eSigners };

    if (missingSigners.length > 0) {
      //save signers
      StoreX.instance.server.postApi<ServerResponse<IFormGroup>>(`../GenericForms/SavePacket`, newPacket).then((x) => {
        if (x.Success) {
          setPacket(x.Value);
          props.update(x.Value);
        } else {
          toast.error(`Error saving packet. ${x.Message}`, {
            autoClose: false,
          });
        }
      });
    } else {
      setPacket(newPacket);
      props.update(newPacket);
    }
  };

  const saveSigner = (close?: boolean) => {
    let signers = [...(packet.Signers ?? [])];
    let found = false;
    for (let i = 0; i < signers.length; i++) {
      let signer = signers[i];
      if (signer.PublicId === selectedSigner?.PublicId) {
        signers[i] = selectedSigner;
        found = true;
        break;
      }
    }

    if (!found && selectedSigner) signers.push(selectedSigner);

    let p = { ...packet, Signers: signers };

    StoreX.instance.server.postApi<ServerResponse<IFormGroup>>(`../GenericForms/SavePacket`, p).then((x) => {
      if (x.Success) {
        setPacket(x.Value);
        props.update(x.Value);
        if (close === true) {
          setShowSignModal(false);
          setSelectedSigner(undefined);
        }
      } else {
        toast.error(`Error saving packet. ${x.Message}`, {
          autoClose: false,
        });
      }
    });
  };
  const saveAndCloseSigner = () => {
    saveSigner(true);
  };

  const updateWizardAndSave = (qs: IWizardQuestion[]) => {
    let p = { ...packet, WizardQuestions: qs };

    StoreX.instance.server.postApi<ServerResponse<IFormGroup>>(`../GenericForms/SavePacket`, p).then((x) => {
      if (x.Success) {
        setPacket(x.Value);
        props.update(x.Value);
      } else {
        toast.error(`Error saving packet. ${x.Message}`, {
          autoClose: false,
        });
      }
    });
  };

  return (
    <>
      <div className="group-builder">
        {!selectedForm && (
          <>
            <Tabs>
              <TabList>
                <Tab>Packet</Tab>
                <Tab>Forms</Tab>
                <Tab>Signers</Tab>
                <Tab>Form Wizard</Tab>
              </TabList>
              <TabPanel>
                <h4>Packet Builder</h4>
                A packet is a collection of forms or paperwork that you'll need to use and collect. Fill out the following form to start creating the set of custom forms you need to use and collect.
                <hr />
                <div className="form-horizontal">
                  <div className="form-group">
                    <label htmlFor="PacketName" className="control-label col-sm-4">
                      Packet Name
                    </label>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        id="PacketName"
                        type="text"
                        value={packet.Name}
                        onChange={(e) => {
                          setPacket({ ...packet, Name: e.target.value });
                        }}
                        maxLength={250}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="col-sm-8 col-sm-offset-4">
                      <input
                        className="form-controlx"
                        id="PacketInUse"
                        type="checkbox"
                        checked={packet.InUse}
                        onChange={(e) => {
                          setPacket({ ...packet, InUse: e.target.checked });
                        }}
                      />
                      <label htmlFor="PacketInUse" className="control-label">
                        Active, this packet is currently turned on.
                      </label>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="control-label col-sm-4">Description</label>
                    <div className="col-sm-8">
                      <Editor
                        options={'simple'}
                        value={packet.Description ?? ''}
                        changed={(v) => {
                          setPacket({ ...packet, Description: v });
                        }}
                        height="short"
                      />
                    </div>
                  </div>
                  <h3>Packet Filters</h3>
                  If this packet only applies to a specific division, category, or entry type you can set those filters here. If you leave these blank the packet will be available to all divisions,
                  categories, and entry types.

                  <div className="form-group">
                    <label
                      htmlFor="EntryTypes"
                      className="control-label col-sm-4"
                    >
                      Entry Types
                    </label>
                    <div className="col-sm-8">
                      <Select
                      id="EntryTypes"
                        options={props.entryTypes.map((x) => {return {label:x.name, value:x.id+''}})}
                        value={props.entryTypes.filter((x) => packet.ForEntryTypes?.includes(+x.id)).map((x) => {return {label:x.name, value:x.id+''}})}
                        onChange={(e) => {
                          let x = e?.map((r) => +r.value) ?? [];
                          console.log(x);
                          setPacket({ ...packet, ForEntryTypes: x.map(x=>x) });
                        }}
                        isMulti={true}
                      />
                    </div>
                  </div>
                  {/* <div className="form-group">
                    <label
                      htmlFor="Categories"
                      className="control-label col-sm-4"
                    >
                      Categories
                    </label>
                    <div className="col-sm-8">
                      <Select
                      id="Categories"
                        options={props.categories.map((x) => {return {label:x.name, value:x.id}})}
                        value={props.categories.filter((x) => packet.ForCategories?.includes(+x.id)).map((x) => {return {label:x.name, value:x.id}})}
                        onChange={(e) => {
                          setPacket({ ...packet, ForCategories: e?.map((x) => +x.value) ?? [] });
                        }}
                        isMulti={true}
                      />
                    </div>
                  </div> */}
                  <div className="form-group">
                    <label
                      htmlFor="Divisions"
                      className="control-label col-sm-4"
                    >
                      Divisions
                    </label>
                    <div className="col-sm-8">
                      <Select
                      id="Divisions"
                        options={props.divisions.map((x) => {return {label:x.name, value:x.id+''}})}
                        value={props.divisions.filter((x) => packet.ForDivisions?.includes(+x.id)).map((x) => {return {label:x.name, value:x.id+''}})}
                        onChange={(e) => {
                          setPacket({ ...packet, ForDivisions: e?.map((x) => +x.value) ?? [] });
                        }}
                        isMulti={true}
                      />
                    </div>
                  </div>
                  <h3>Packet Review</h3>
                  <p>What options are avaiable for review for this packet...</p>
                  <div className="form-group">
                    <label
                      htmlFor="EntryTypes"
                      className="control-label col-sm-4"
                    >
                      ...
                    </label>
                    <div className="col-sm-8">
                      ...
                    </div>
                  </div>

                </div>
              </TabPanel>
              <TabPanel>
                <div className="form-horizontal">
                  <div className="form-group">
                    <div className="col-sm-12">
                      <div className="flex-between">
                        <h4>Forms</h4>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => {
                            setSelectedForm(blankForm());
                          }}>
                          <Icon type={IconType.plus} /> New Form
                        </button>
                      </div>
                      {packet.Forms.length === 0 && (
                        <div>
                          Currently this packet is empty and does not have any forms. Click{' '}
                          <strong>
                            <Icon type={IconType.plus} /> New Form
                          </strong>{' '}
                          to add a new form.
                        </div>
                      )}
                      {packet.Forms.length > 0 && (
                        <div className="packet-form-list">
                          {packet.Forms.map((x, i) => {
                            return (
                              <div className="packet-form-tile" key={`form-tile-${i}`}>
                                <div className="flex-between">
                                  <h4 title={`${x.Name}`}>{x.Name}</h4>
                                </div>
                                <div className="flex-between">
                                  {x.FilePath && (
                                    <span>
                                      <Icon type={IconType.filePdf} /> Has File
                                    </span>
                                  )}
                                  {x.Fields && <span>Fields: {x.Fields?.length}</span>}
                                </div>
                                <div className="packt-form-tile-body">
                                  {x.Description && (
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: x.Description,
                                      }}
                                    />
                                  )}
                                </div>
                                <div className="text-right">
                                  <button
                                    type="button"
                                    className="btn btn-default"
                                    onClick={() => {
                                      setSelectedForm(x);
                                    }}>
                                    <Icon type={IconType.edit} /> Edit
                                  </button>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <h4>Packet Builder Signers</h4>
                This is a list of all of the diffent parties that will need to sign this form. If no signers are required this list should be blank. If signers are required signers will appear as you
                build out this packets forms. This way a signle signer can sign muiltiple forms within this packet.
                <hr />
                <div className="flex-between">
                  <h4>Signers</h4>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    title="click to add new signer"
                    onClick={() => {
                      setSelectedSigner({
                        Description: '',
                        Instructions: '',
                        IsSelf: false,
                        Name: '',
                        PublicId: StoreX.NewGuid(),
                      });
                    }}>
                    <Icon type={IconType.plus} /> New Signer
                  </button>
                </div>
                <div className="form-horizontal">
                  {!packet.Signers || (packet.Signers.length === 0 && <>No Signers have been defined yet.</>)}
                  {packet.Signers && packet.Signers.length > 0 && (
                    <>
                      <div className="signers-list">
                        {packet.Signers.map((x, i) => {
                          return (
                            <div key={`signer-${i}`} className="signer">
                              <span
                                className="click"
                                title="click to edit signer"
                                onClick={() => {
                                  setSelectedSigner(x);
                                }}>
                                <Icon type={IconType.edit} />
                              </span>
                              <span>{x.Name}</span>
                              {x.IsSelf && (
                                <>
                                  &nbsp;&nbsp;&nbsp;<span className="label label-info">self</span>
                                </>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>
              </TabPanel>
              <TabPanel>
                <FormsWizardBuilder packet={packet} updateAndSave={updateWizardAndSave} />
              </TabPanel>
            </Tabs>
            <div className="form-horizontal">
              <hr />
              <div className="form-group">
                <div className="col-sm-12 flex-between">
                  <div>
                    <button type="button" className="btn btn-secondary" onClick={saveAndClose}>
                      <Icon type={IconType.save} /> Save & Close Packet
                    </button>
                    <button type="button" className="btn btn-default" onClick={()=>{save();}}>
                      <Icon type={IconType.save} /> Save
                    </button>
                  </div>
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={() => {
                      props.close();
                    }}>
                    <Icon type={IconType.close} /> Close
                  </button>
                </div>
              </div>
            </div>
          </>
        )}

        {selectedForm && (
          <>
            <div className="flex-between">
              <h3>Edit Form: <strong>{selectedForm.Name || 'Untitled'}</strong></h3>
              <div>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    setSelectedForm(undefined);
                  }}>
                  <Icon type={IconType.close} /> Close Form
                </button>
              </div>
            </div>
            <div>
              <FormBuilder
                close={() => {
                  setSelectedForm(undefined);
                }}
                form={selectedForm}
                update={formUpdated}
                signers={
                  packet.Signers?.map((x) => {
                    return { label: x.Name, value: x.PublicId };
                  }) ?? []
                }
                packet={packet}
                categories={props.categories}
                divisions={props.divisions}
                entryTypes={props.entryTypes}
              />
            </div>
          </>
        )}
      </div>

      {showSignModal && (
        <Modal setModalOpen={setShowSignModal} title={`Signer Information: ${selectedSigner?.Name}`} size={'l'}>
          <div className="form-horizontal signer-form">
            <div className="form-group">
              <label htmlFor="form-publicId" className="col-sm-4 control-label">
                Id
              </label>
              <div className="col-sm-8">
                <input id="form-publicId" type="textbox" readOnly={true} maxLength={500} className="form-control" value={selectedSigner?.PublicId} />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="form-signer-name" className="col-sm-4 control-label">
                Name
              </label>
              <div className="col-sm-8">
                <input
                  id="form-signer-name"
                  type="textbox"
                  maxLength={500}
                  className="form-control"
                  value={selectedSigner?.Name}
                  onChange={(e) => {
                    setSelectedSigner({ ...selectedSigner, Name: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-8 col-sm-offset-4">
                <input
                  id="form-signer-self"
                  type="checkbox"
                  checked={selectedSigner?.IsSelf}
                  onChange={(e) => {
                    setSelectedSigner({ ...selectedSigner, IsSelf: e.target.checked });
                  }}
                />

                <label htmlFor="form-signer-self">User is signer</label>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="form-signer-instructions" className="col-sm-4 control-label">
                Instructions for Signer
              </label>
              <div className="col-sm-8">
                <Editor
                  options={'simple'}
                  value={selectedSigner?.Instructions ?? ''}
                  changed={(v) => {
                    console.log(v);
                    setSelectedSigner({ ...selectedSigner, Instructions: v });
                  } } 
                  height={'short'}                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="form-signer-description" className="col-sm-4 control-label">
                Description for Signer
              </label>
              <div className="col-sm-8">
                <Editor
                  options={'simple'}
                  value={selectedSigner?.Description ?? ''}
                  changed={(v) => {
                    setSelectedSigner({ ...selectedSigner, Description: v });
                  }}
                  height={'short'}
                />
              </div>
            </div>

            <div className="form-group">
              <div className="col-sm-12 flex-between">
                <div>
                  <button type="button" className="btn btn-secondary" onClick={saveAndCloseSigner}>
                    <Icon type={IconType.save} /> Save & Close Form
                  </button>
                  <button type="button" className="btn btn-default" onClick={()=>{saveSigner()}}>
                    <Icon type={IconType.save} /> Save
                  </button>
                </div>
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={() => {
                    setShowSignModal(false);
                  }}>
                  <Icon type={IconType.close} /> Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default PacketBuilder;
