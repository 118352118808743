import React, { useEffect, useState, useRef, useCallback } from "react";
import StoreX from "../../redux/oldStore";
import Icon, { IconType } from "../Icon/Icon";
import * as PDFJS from "pdfjs-dist";
import type {
  PDFDocumentProxy,
  RenderParameters,
} from "pdfjs-dist/types/src/display/api";
import "./PdfStyle.css";
import PdfBasicToolbar from "./PdfBasicToolbar";
import PdfInput from "./PdfInput";
import { IFieldInfo, ISigner } from "../GenericForms/formTypes";
import { toast } from "react-toastify";
import PdfFooterToolbar from "./PdfFooterToolbar";
import PdfViewerPage from "./PdfViewerPage";

interface IPdfViewerProps {
  id: string;
  source?: string;
  values?: any;
  updateValues?: Function;
  close?: Function;
  fieldMetaData?:IFieldInfo[];
  signer?:ISigner;
  signRequested?:Function;
  downloadFormUrl?:string;
  viewOnly?:boolean;
  isSetupMode?:boolean;
  fieldSelected?:(field: IFieldInfo)=>void;
  removeFile?:()=>void;  
}

const PdfViewer = (props: IPdfViewerProps) => {
  PDFJS.GlobalWorkerOptions.workerSrc =
    "https://unpkg.com/pdfjs-dist@4.9.155/build/pdf.worker.min.mjs";

  const [pdfRef, setPdfRef] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const [scale, setScale] = useState(1.5);
  const [zoom, setZoom] = useState(1);
  const [formValues, setFormValues] = useState<any>(props.values ?? {});
  const [formValuesSet, setFormValuesSet] = useState<any>({});
  const [signer, setSigner] = useState<ISigner>();
  const [missingFields, setMissingFields] = useState<Record<string,IFieldInfo>>({});
  const [pages, setPages] = useState<any[]>([]);
  
  let fmd = {};
  if(props.fieldMetaData){
    props.fieldMetaData.forEach(x=>{if(x.FormKey) fmd[x.FormKey] = x});
  }

  const [fieldsMetaData, setFieldsMetaData] = useState<any>(fmd);


  useEffect(()=>{
    setSigner(props.signer);
  }, [props.signer])

  const renderPage = useCallback(
    async (pageNum, pdf = pdfRef) =>  {
      if(pdf){     
        let xPages:any[] = [];
        for(let i = 0; i < pdf.numPages; i++)   {
        await pdf.getPage(i+1).then(function (page) {
          if(i === 0) setPages([page]);
          xPages.push(page);
        });

      }
        setPages(xPages);
      }
    },
    [pdfRef, props.values]
  );

  useEffect(() => {
    renderPage(currentPage, pdfRef);
  }, [pdfRef, currentPage, renderPage]);

  useEffect(() => {
    const fullUrl = StoreX.BuildUrl(props.source ?? "");
    const loadingTask = PDFJS.getDocument(fullUrl);
    loadingTask.promise.then(
      (loadedPdf) => {
        setPdfRef(loadedPdf);
      },
      function (reason) {
        console.error(reason);
      }
    );
  }, [props.source]);

  const inputValueChanged = (id: string, value: string) => {
    let newValues = { ...formValues, [id]: value };    
    setFormValues(newValues);

    let valuesSet = {...formValuesSet, [id]: value};
    setFormValuesSet(valuesSet);

  };

  const saveClick = (save: boolean, close: boolean) => {
    if(props.signer){
      //this is to submit signed stuff...
      if(save && props.updateValues){

        //was everything signed that needed to be?
        let signerFields = props?.fieldMetaData?.filter(x=> x.SignatureOwner === props.signer?.SignatureInfoId && x.Type !== 'Btn' && !x.Hidden) ?? [];

        let missing = signerFields.filter(x=> !formValuesSet[x.FormKey ?? '|#|'] && !formValues[x.FormKey ?? '|#|']);
        let missingDic:Record<string,IFieldInfo> = {};
        missing.forEach(x=>{missingDic[x.PublicId]=x});
        setMissingFields(missingDic);

        let valuesToSave = {...formValuesSet};

        signerFields.forEach(x=>{
          if(!valuesToSave[x.FormKey ?? '|#|']){
            valuesToSave[x.FormKey ?? '|#|'] = formValues[x.FormKey ?? '|#|'];
          }
        });

        if(missing.length > 0){
          toast.warning(`You have not filled out / signed all of the information you need to on this page.`);
          missing.forEach(x=>{
            console.log(`""${x.FormKey}""`, x.Label, x.Type, x.FormKey, x.Hidden);
            console.log(x);
            console.log(formValuesSet);
            console.log(formValues);
          })
          //if(!window.confirm('Are you sure you want to submit? You have not filled everything out yet.'))
          return;
        }

        props.updateValues(props.id, valuesToSave, close);
        setFormValuesSet({});
        return;
      }

      if(close && props.close){
        if(Object.keys(formValuesSet).length > 0 && !window.confirm(`If you close now your signature will not be saved. Are you sure you want to close?`)){
          return;
        }
        props.close();
      }
    }
    if (save && props.updateValues) {
      props.updateValues(props.id, formValues, close);
      return;
    }
    if (close && props.close) {
      props.close();
    }
  };



  const signingRequested = (signatureOwner:string)=>{
    if(props.signRequested)
    props.signRequested(signatureOwner);
  }

  return (
    <>
      <div className="zpdf">
        <div className="zpdf-desk">
          <div className="zpdf-container">
            <PdfBasicToolbar
              page={currentPage}
              // pages={pdfRef?.numPages ?? 0}
              pages={0}
              viewOnly={props.viewOnly ?? false}
              changePage={(n) => {
                setCurrentPage(n);
              }}
              zoom={zoom}
              changeZoom={(n) => {
                setZoom(n);
              }}
              save={saveClick}
              isSigning={signer?true:false}
              downloadFormUrl={props.downloadFormUrl}
              removeFile={props.removeFile}
            />
            <div className="zpdf-viewer">
              
              {pages && pages.map((p,i)=>{
              return <PdfViewerPage 
                        key={`pdf-v-p-k-${i}`}
                        viewOnly={props.viewOnly ?? false} 
                        scale={scale} 
                        inputValueChanged={inputValueChanged} 
                        formValues={formValues} 
                        fieldsMetaData={fieldsMetaData} 
                        missingFields={missingFields} 
                        signingRequested={signingRequested} 
                        isSetupMode={props.isSetupMode ?? false} 
                        page={p}
                        scrollTo={(i+1)==currentPage} 
                        signer={signer}
                        fieldSelected={(field:any)=>{
                          console.log('Pdf Viewer Field Selected ', field);
                          if(props.fieldSelected) {
                            props.fieldSelected(field);
                            console.log('Called Field Selected');
                          } else {
                            console.warn('Method not definded for fieldSelected');
                          }
                        }}
                        />
            })}
              
            </div>
            
            <PdfFooterToolbar
              page={currentPage}
              // pages={pdfRef?.numPages ?? 0}
              pages={0}
              changePage={(n) => {
                setCurrentPage(n);
              }}
              zoom={zoom}
              changeZoom={(n) => {
                setZoom(n);
              }}
              save={saveClick}
              isSigning={signer?true:false}
              downloadFormUrl={props.downloadFormUrl}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PdfViewer;
