import React, { useState, useEffect, useRef } from 'react';
import Icon, { IconType } from '../Icon/Icon';
import imgClouds from '../../../public/Assets/Images/clouds.jpg';
import PdfViewer from '../PdfViewer/PdfViewer';
import { toast } from 'react-toastify';
import { UiValuePairs } from '../../utils/CommonTypes/UserTypes';
import Modal from '../Modal/Modal';
import { stringify } from 'query-string';

interface IExpandableDivProps {
  children?: React.ReactNode;
}

const ExpandableDiv = (props: IExpandableDivProps) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className={`expandable-div ${open ? 'open' : 'closed'}`}>
      {props.children}
      <div className="bottom-gradient">
        {open ? (
          <button
            type="button"
            onClick={() => {
              setOpen(false);
            }}
            className="more-button btn btn-default btn-xs">
            <Icon type={IconType.up} /> See less
          </button>
        ) : (
          <button
            type="button"
            onClick={() => {
              setOpen(true);
            }}
            className="more-button btn btn-default btn-xs">
            <Icon type={IconType.down} /> See more
          </button>
        )}
      </div>
    </div>
  );
};

export default ExpandableDiv;
interface IViewrContainerProps {
  children?: React.ReactNode;
}

export const ViewerContainer = (props: IViewrContainerProps) => {};

export interface IExpandableImgProps {
  imageSrc: IImage[];
  name?: string;
  onClick?: Function;
}

export interface IImage {
  path: string;
  alt: string;
  missing?: boolean;
  type?:'person' |'other';
}

export const ExpandableImg = (props: IExpandableImgProps) => {
  const [images, setImages] = useState<IImage[]>(props.imageSrc ?? []);
  const [index, setIndex] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [showFull, setShowFull] = useState<boolean>(false);
  const [showBig, setShowBig] = useState<boolean>(false);

  return (
    <div
      className={`expandable-img ${open ? 'open' : ''}`}
      onMouseDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (open) {
          setOpen(false);
        }
      }}>
      {open && (
        <div
          className="close-btn"
          onClick={() => {
            setOpen(false);
          }}>
          <Icon type={IconType.close} />
        </div>
      )}
      { !(images[index]?.missing ?? false)  ? <img
        alt={images[index]?.alt ?? ''}
        src={images[index]?.path}
        onError={({ currentTarget: x }) => {
          const i = index + 1;
          if (i - 1 >= images.length || !images[i]) {
            x.onerror = null; // prevents looping
            x.src = imgClouds;
          } else {
            x.src = images[i].path;
            x.alt = images[i].alt;
          }
          setIndex(i);
        }}
        onClick={(e) => {
          if (!open) {
            if (props?.onClick) {
              props.onClick();
              return;
            }
            setOpen(true);
          } else {
            console.log('stop');
            e.stopPropagation();
          }
        }}
      />:<>
      <div className="missing-image">
        <Icon type={images[index]?.type === 'person' ? IconType.userLight : IconType.image} />
      </div>
      </>}
      {images[index]?.alt && <div className="caption">{images[index].alt}</div>}
      {props?.name && open && <div>{props.name}</div>}
    </div>
  );
};

interface ILinkToView {
  type: 'pdf' | 'video';
  urlSource: string;
  name?: string;
  fill?: boolean;
  thumbnail?: string;
}
export const LinkToView = (props: ILinkToView) => {
  const [open, setOpen] = useState<boolean>(false);
  const [url, setUrl] = useState<string>('');

  useEffect(() => {
    let u = props.urlSource;
    if (u.startsWith('https://localhost')) u = u.replace('https://', 'http://');
    setUrl(u);
  }, []);

  const renderIt = () => {
    switch (props.type) {
      case 'video':
        return renderVideoClosed();
        break;
      case 'pdf':
        return renderPdfClosed();
        break;
    }
  };
  const renderItOpen = () => {
    switch (props.type) {
      case 'video':
        return renderVideo();
        break;
      case 'pdf':
        return renderPdf();
        break;
    }
  };

  const renderPdfClosed = () => {
    return (
      <span
        className={`link-to-show ${props.fill ? 'fill' : ''}`}
        title={`Click to view`}
        onClick={(e) => {
          setOpen(true);
        }}>
        <Icon type={IconType.filePdf} /> {props.name ?? props.urlSource}
        {props.thumbnail && (
          <img
            src={props.thumbnail}
            onError={(e) => {
              (e.target as HTMLImageElement).style.display = 'none';
            }}
          />
        )}
      </span>
    );
  };

  const renderVideoClosed = () => {
    return (
      <span
        className={`link-to-show ${props.fill ? 'fill' : ''}`}
        title={`Click to view`}
        onClick={(e) => {
          setOpen(true);
        }}>
        <Icon type={IconType.film} /> {props.name ?? props.urlSource}
      </span>
    );
  };

  const viewer = (children: React.ReactNode) => {
    return (
      <div
        className="easy-viewer-container"
        onClick={(e) => {
          setOpen(false);
          e.stopPropagation();
        }}>
        <div
          className="easy-viewer"
          onClick={(e) => {
            e.stopPropagation();
          }}>
          <div className="viewer-header">
            <div className="viewer-title">{props.name ?? props.urlSource}</div>
            <div>
              <span
                className="click"
                title="click to close"
                onClick={() => {
                  setOpen(false);
                }}>
                <Icon type={IconType.close} />
              </span>
            </div>
          </div>
          <div className="viewer-body">{children}</div>
          <div className="viewer-footer">
            <hr />
            <a href={url} className="click" title="click to view file" target="_blank">
              {props.type === 'pdf' && <Icon type={IconType.filePdf} />}
              {/* {props.name ?? props.urlSource} */}
              Trouble Viewing? Open in new tab.
            </a>
          </div>
        </div>
      </div>
    );
  };

  const renderPdf = () => {
    return (
      <>
        {viewer(
          <>
            <PdfViewer id={'x'} source={url} signRequested={() => {}} viewOnly={true} />
          </>
        )}
      </>
    );
  };

  const renderVideo = () => {
    return (
      <>
        {viewer(
          <>
            <video width="100%" height="100%" controls>
              <source src={url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </>
        )}
      </>
    );
  };

  return (
    <>
      {!open && <>{renderIt()}</>}
      {open && <>{renderItOpen()}</>}
    </>
  );
};

interface IImageDetails {
  comments?: string;
  path: string;
}

interface ISlickImage {
  image: IImageDetails;
  noDefaultImage?: boolean;
}

export const SlickImage = (props: ISlickImage) => {
  const getImage = () => {
    let image = props.image;

    if (image) {
      return (
        <div className="tile-image">
          <img
            alt={image.comments}
            src={image.path}
            className="body-grow"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.style.display = 'none';
            }}
          />
          <img
            alt={image.comments}
            src={image.path}
            className="body-grow img-back"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              if (props.noDefaultImage) {
                currentTarget.style.display = 'none';
              } else {
                currentTarget.src = imgClouds;
              }
            }}
          />
        </div>
      );
    }

    return (
      <div className="tile-image">
        <img src={imgClouds} className="stock-image body-grow" />
      </div>
    );
  };

  return <>{getImage()}</>;
};

interface ISlickImageGroup {
  images: IImageDetails[];
  onErrorImage?: string;
  errorShow?:React.ReactNode;
  className?:string;
  imageClassName?:string;
}
export const SlickImageGroup = (props: ISlickImageGroup) => {
  const [gridSize, setGridSize] = useState<number>(1);

  useEffect(() => {
    setGridSize(getGridSize());
  }, []);

  const getGridSize = () => {
    const size = props.images.length;
    if (size > 9) return 5;
    if (size > 6) return 4;
    if (size > 4) return 3;
    // if(size > 2) return 2;
    if (size > 1) return 2;
    return 0;
  };
  return (
    <>
      <div className={`slick-image-group no-${gridSize} ${props.className ?? ''}`}>
        {props.images.map((x, i) => {
          return (
            <img
            className={`${props.imageClassName ?? ''}`}
              src={x.path}
              title={x.comments}
              key={`img-gr-${i}`}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                if (props.onErrorImage) {
                  currentTarget.src = props.onErrorImage;
                } else {
                  currentTarget.style.display = 'none';
                }
              }}
            />
          );
        })}
      </div>
    </>
  );
};

interface IEasyImageProps {
  alt?: string;
  src: string;
  link?:string;
  title?:string;
  missingImagePath?: string;
  showCloudsOnError?: boolean;
  className?: string;
}

export const EasyImage = (props: IEasyImageProps) => {
  const [src, setSrc] = useState<string>();
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    setSrc(props.src);
  }, [props.src]);

  const buildImg = () => {
    if(props.link){
      return <a title={props.title} href={props.link}>
        <img
        alt={props.alt}
        src={props.src}
        className={`easy-image ${props.className ?? ''}`}
        onError={(x) => {
          const currentTarget = x.currentTarget;
          if (props.missingImagePath && props.missingImagePath !== currentTarget.src) {
            currentTarget.src = props.missingImagePath;
          } else if (props.showCloudsOnError) {
            currentTarget.src = imgClouds;
          } else {
            setHasError(true);
            //currentTarget.style.display = "none";
          }
        }}
      />
      </a>
    }
    return (
      <img
        alt={props.alt}
        src={props.src}
        className={`easy-image ${props.className ?? ''}`}
        onError={(x) => {
          const currentTarget = x.currentTarget;
          if (props.missingImagePath && props.missingImagePath !== currentTarget.src) {
            currentTarget.src = props.missingImagePath;
          } else if (props.showCloudsOnError) {
            currentTarget.src = imgClouds;
          } else {
            setHasError(true);
            //currentTarget.style.display = "none";
          }
        }}
      />
    );
  };

  return <>{src && <>{!hasError && buildImg()}</>}</>;
};

interface IFileInfo {
  type: 'pdf' | 'image' | 'video' | 'text' | 'other';
  path: string;
  ext: string;
  urlSource?: string;
  fileName?:string;
}

interface IEasyFileInputProps {
  onSelect?: Function;
  onSelectFile?: Function;
  htmlId?: string;
  exts?: string;
  fileSize?: number;
  value?: string;
  valueOptions?: UiValuePairs[];
  filePath?: string;
  fileKey?: string;
  fileName?: string;
}
export const EasyFileInput = (props: IEasyFileInputProps) => {
  const [info, setFileInfo] = useState<IFileInfo>();
  const [showInput, setShowInput] = useState<boolean>(props?.value ? false : true);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [showOptions, setShowOptions] = useState<boolean>();

  useEffect(() => {
    if (props.fileKey) {
      let fi = getFileInfo2(props.filePath ?? '', props.fileName ?? '');
      if(fi)
      setFileInfo(fi);
    } else {
      let fi = getFileInfo(props.value ?? '');
      if (fi && (!info?.urlSource || (info?.urlSource && fi?.path != info.path))) {
        setFileInfo(fi);
      }
    }
  }, [props.value, props.fileKey, props.fileName, props.filePath, props.fileKey]);

  const selectExistingFile = (x: UiValuePairs) => {
    let fi = getFileInfo(x.Value);
    if (props.onSelect) props.onSelect(x.Value);
    setFileInfo(fi ?? undefined);
    setShowOptions(false);
  };

  const onChangeFile = async (e) => {
    if (e.target.files) {
      let file = await isFileValid(e.target.files);

      if (!file) {
        if (e.target) e.target.files = [];
        if (fileInputRef.current) fileInputRef.current.value = '';
        return;
      }

      setFileInfo(file.fileInfo);
      setShowInput(false);
      setShowOptions(false);
      if (props.onSelectFile) props.onSelectFile(file.file);
    }
  };

  const isFileValid = async (files: FileList) => {
    if (!files) return;
    let file = files[0];

    let size = file.size / 1024.0 / 1024.0;
    let name = file.name;
    let fileInfo = getFileInfo(file.name);
    if (!fileInfo) return null;

    if (props.fileSize) {
      if (props.fileSize < size) {
        toast.warning(`The file "${name}" is too large. The max file size is ${props.fileSize}MB.`);
        return null;
      }
    } else {
      if (fileInfo?.type === 'video' && size > 1024) {
        //to big
        toast.warning(`The file "${name}" is too large. The max file size is 1 GB`);
        return null;
      } else if (fileInfo?.type === 'pdf' && size > 50) {
        //to big
        toast.warning(`The file "${name}" is too large. The max file size is 50 MB`);
        return null;
      } else if (size > 20) {
        //to big
        toast.warning(`The file "${name}" is too large. The max file size is 20 MB`);
        return null;
      }
    }

    fileInfo.urlSource = await readURL(file);

    return { file, fileInfo };
  };

  const getFileInfo = (filePath: string | undefined) => {
    if (!filePath || typeof filePath !== typeof ' ') return null;

    let ext = filePath.substring(filePath.lastIndexOf('.')).toLowerCase();
    let isPdf = ext === '.pdf';
    let isImage = ext === '.jpg' || ext === '.jpeg' || ext === '.png' || ext === '.tif' || ext === '.tiff' || ext === '.gif';
    let isVideo = ['.mov', '.mp4'].find((x) => x === ext);
    let isText = ['.csv', '.xml', '.txt'].find((x) => x === ext);
    let type:FileType = isPdf ? 'pdf' : isImage ? 'image' : isVideo ? 'video' : isText ? 'text' : 'other';

    let result: IFileInfo = { type: type, path: filePath, ext: ext };
    return result;
  };
  const getFileInfo2 = (filePath: string, fileName: string) => {
    if (!filePath || typeof filePath !== typeof ' ') return null;

    let ext = fileName.substring(fileName.lastIndexOf('.')).toLowerCase();
    let isPdf = ext === '.pdf';
    let isImage = ext === '.jpg' || ext === '.jpeg' || ext === '.png' || ext === '.tif' || ext === '.tiff' || ext === '.gif' || ext === '.svg';
    let isVideo = ['.mov', '.mp4'].find((x) => x === ext);
    let isText = ['.csv', '.xml', '.txt'].find((x) => x === ext);
    let type:FileType = isPdf ? 'pdf' : isImage ? 'image' : isVideo ? 'video' : isText ? 'text' : 'other';

    let result: IFileInfo = { type: type, path: filePath, ext: ext, fileName:fileName };
    return result;
  };

  const readURL = (file) => {
    return new Promise<string>((res, rej) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target && e.target.result) {
          res(e.target.result as string);
        } else {
          rej(new Error('File reading error'));
        }
      };
      reader.onerror = (e) => rej(e);
      reader.readAsDataURL(file);
    });
  };

  const showFile = () => {
    let name = info?.fileName ?? props.valueOptions?.find((x) => x.Value === info?.path)?.Name ?? info?.path ?? 'Unknown File Name';
    if (info?.type === 'image') {
      return (
        <div className="flex-between">
          <img src={info.urlSource ?? info.path} className="thumbnail-1" />
          {name && <span><strong>{name}</strong></span>}
          <div className='bumper'>
          <span
            className="click"
            onClick={() => {
              setShowOptions(true);
            }}>
            <Icon type={IconType.edit} />
          </span>
          <span
            className="click"
            onClick={() => {
              if(window.confirm('Are you sure you want to remove this selected file?')){
                setFileInfo(undefined);
                if (props.onSelect) props.onSelect();
              }
            }}>
            <Icon type={IconType.trashO} />
          </span>
          </div>
        </div>
      );
    } else if (name && (name.toLowerCase().indexOf('http') == 0 || name.toLowerCase().indexOf('../') === 0)) {
      return (
        <div className="flex-between">
          <img src={name} className="thumbnail-1" />
          <span
            className="click bumper"
            onClick={() => {
              setShowOptions(true);
            }}>
            <Icon type={IconType.edit} />
          </span>
        </div>
      );
    } else {
      // if (info?.urlSource){
      return (
        <div className="flex-between">
          <strong>{name}</strong>
          <span
            className="click bumper"
            onClick={() => {
              setShowOptions(true);
            }}>
            <Icon type={IconType.edit} />
          </span>
        </div>
      );
    }
  };

  const nameOrFile = (x: UiValuePairs) => {
    let file = getFileInfo(x.Value);
    if (file && file.path) {
      switch (file.type) {
        case 'image':
          return (
            <div className="flex-between">
              <div className="thumbnail-1 click">
                <ExpandableImg
                  imageSrc={[{ alt: x.Name, path: file.path }]}
                  name={x.Name}
                  onClick={() => {
                    selectExistingFile(x);
                  }}
                />
              </div>
              <span className="bumper">{x.Name}</span>
            </div>
          );
        case 'pdf':
          return <LinkToView type="pdf" urlSource={file.path} name={x.Name} />;
        case 'video':
          return <LinkToView type="video" urlSource={file.path} name={x.Name} />;
        case 'text':
          break;
        case 'other':
          break;
      }
    }
    return <strong>{x.Name}</strong>;
  };

  return (
    <>
      <div>
        {showOptions || !info ? (
          <>
            {!info && (
              <button
                type="button"
                className="btn btn-secondary btn-xs"
                onClick={() => {
                  setShowOptions(true);
                }}>
                <Icon type={IconType.upload} /> Select File
              </button>
            )}
            {info && (
              <div className="flex-between">
                <strong>{info?.fileName ?? info?.path ?? 'Unknown Name'}</strong>
                <span
                  className="click bumper"
                  onClick={() => {
                    setShowOptions(false);
                  }}>
                  <Icon type={IconType.close} />
                </span>
              </div>
            )}
          </>
        ) : (
          <div className='file-picker'>{showFile()}</div>
        )}
      </div>
      {showOptions && (
        <Modal setModalOpen={setShowOptions} title={'Existing Files'} size="m">
          <div className="existing-file-container">
            {props.valueOptions?.map((x, i) => {
              let fi = getFileInfo(x.Value);
              return (
                <div key={`efo-${i}`} className="file-picker existing-file-item flex-between">
                  {nameOrFile(x)}{' '}
                  <button
                    type="button"
                    className="btn btn-secondary btn-xs"
                    onClick={() => {
                      selectExistingFile(x);
                    }}>
                    <Icon type={IconType.save} />
                  </button>
                </div>
              );
            })}

            <div>
              <h4>Upload File</h4>
              Not finding the file you need, upload one.
              <hr />
              <input id={`${props.htmlId}`} type="file" className="form-control" ref={fileInputRef} onChange={onChangeFile} accept={props.exts} />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};


export type FileType = 'pdf' | 'image' | 'video' | 'text' | 'other';
