import { connect } from 'react-redux';
import { IState } from '../../redux/redux';
import Button from '../_Core/Button';
import { store } from '../../redux/oldStore';
import { ServerResponse } from '../../utils/Server';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { FormatDate } from '../../utils/Tools';
import Icon, { IconType } from '../Icon/Icon';

interface IProps extends IState {}

interface IStartedRegistrationsResponse {
  people: IStartedRegistration[];
}

interface IStartedRegistration {
  firstName: string;
  lastName: string;
  email: string;
  emailConfirmed: boolean;
  id: string;
  startedAtUtc: string;
  school: string;
  continueUrl: string;
  step: string;
  type: string;
}

const StartedRegistrations = (props: IProps) => {
  const [data, setData] = useState<IStartedRegistrationsResponse>();

  useEffect(() => {
    if (props.User) {
      init();
    }
  }, [props.User]);

  const init = () => {
    store.server.getApi<ServerResponse<IStartedRegistrationsResponse>>('../PartiallyRegistered/List').then((x) => {
      if (x.Success) {
        setData(x.Value);
      } else {
        toast.error(x.Message);
      }
    });
  };

  const sendReminder = (person: IStartedRegistration) => {
    store.server.getApi<ServerResponse<string>>(`../PartiallyRegistered/SendReminder/${person.id}`).then((x) => {
      if (x.Success) {
        toast.success(x.Value);
      } else {
        toast.error(x.Message);
      }
    });
  };

  const deleteRegistration = (person: IStartedRegistration) => {
    if (!window.confirm(`Are you sure you want to delete the started reistration for: "${person.firstName} ${person.lastName}", this cannot be undone?`)) return;

    store.server.getApi<ServerResponse<string>>(`../PartiallyRegistered/Remove/${person.id}`).then((x) => {
      if (x.Success) {
        toast.success(x.Value);
      } else {
        toast.error(x.Message);
      }
    });
  };

  const registrationType = (type: string) => {
    switch (type.toLowerCase()) {
      case '0':
      case 'participant':
      case 'student':
        return <Icon type={IconType.child} title='Participant' />;
      case 'judge':
      case '5':
        return <Icon type={IconType.gavel} title='Judge' />;
      case 'staff':
      case '7':
      case '10':
        return <Icon type={IconType.userCrown} title='Staff or Admin' />;
      case 'volunteer':
      case '3':
        return <Icon type={IconType.handsHelping} title='Volunteer' />;
      case 'teacher':
      case '1':
        return <Icon type={IconType.chalkboardTeacher} title='Teacher' />;
      default:
        return <Icon type={IconType.questionCircle} title='Unknow user type'/>;
    }
  };

  return (
    <>
      <div className="started-registrations">
        <div className="header flex-between">
          <div>
            <h3>Started Registrations</h3>
          </div>
          <div>
            <Button iconOnly={true} type={'refresh'} onClick={init} />
          </div>
        </div>
        <p>
          The following registrations have been started but not completed, you can send a reminder to the user to complete the registration, delete the registration or complete the registration on
          behalf of the user.
        </p>
        <div className="list-container">
          {!data && <div>Loading...</div>}
          {data && data.people.length === 0 && <div>No started registrations found.</div>}
          {data && data.people.length > 0 && (
            <>
              {data.people.map((person, index) => {
                return (
                  <div key={index} className="list-item flex-between">
                    <div className="flex">
                      <div className="icon">{registrationType(person.type)}</div>
                      <div>
                        <h4>
                          {person.firstName} {person.lastName}
                        </h4>
                        <div className="email">{person.email} {person.emailConfirmed && <Icon type={IconType.checkCircle} title='Confirmed reciept of email.' addClass='text-success' />} </div>
                        <div className="school">{person.school}</div>
                      </div>
                    </div>
                    <div>
                        {/* <div>Step: {person.step} </div> */}
                      <div className="date">
                        <div className='flex-between'>
                            <strong>{FormatDate(person.startedAtUtc, 'pretty')}</strong>    
                            <span><a href={person.continueUrl} >Continue</a></span>
                        </div>
                      </div>
                      <div className="btn-group">
                        <Button type={'send'} onClick={() => sendReminder(person)} text="Send Reminder" />
                        <Button type={'delete'} onClick={() => deleteRegistration(person)} text="Remove" />
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return { ...state.main };
};

export default connect(mapStateToProps)(StartedRegistrations);
