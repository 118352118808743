import React, { useEffect, useState, useRef, useCallback } from "react";
import Icon, { IconType } from "../Icon/Icon";
import { IFieldInfo, IFormDetail } from "./formTypes";
// import pdfjsLib from "pdfjs-dist/build/pdf";
// import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

import * as PDFJS from "pdfjs-dist";
import StoreX from "../../redux/oldStore";
import { v4 as uid } from "uuid";
import Modal from "../Modal/Modal";
import Creatable from "react-select/creatable";
import { Selectable } from "../../utils/CommonTypes/CommonTypes";
import PdfViewer from "../PdfViewer/PdfViewer";
import { toast } from "react-toastify";
import { ServerResponse } from "../../utils/Server";

interface IFieldsManagerProps {
  form: IFormDetail;
  fields: IFieldInfo[];
  fieldsUpdated: Function;
  filePath?: string;
  formId: string;
  signers: Selectable[];
}

const FieldsManager = (props: IFieldsManagerProps) => {
  const [pdfRef, setPdfRef] = useState<any>();
  const [pdfRefNew, setPdfRefNew] = useState<any>();
  const [fields, setFields] = useState<IFieldInfo[]>([]);
  const [newfields, setNewFields] = useState<IFieldInfo[]>([]);
  const [newFieldsToSave, setNewFieldsToSave] = useState<IFieldInfo[]>([]);
  const [fieldsPulled, setFieldsPulled] = useState<boolean>();
  const [selectedField, setSelectedField] = useState<IFieldInfo>();
  const [showEditField, setShowEditField] = useState<boolean>(false);
  const [signers, setSigners] = useState<Selectable[]>(props.signers ?? []);
  const [signersDic, setSignersDic] = useState<any>({});
  const [form, setForm] = useState<IFormDetail>(props.form);
  const [showGoBig, setShowGoBig] = useState<boolean>(false);
  const [oldField, setOldField] = useState<IFieldInfo|undefined>();
  const [newField, setNewField] = useState<IFieldInfo|undefined>();

  //pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
  
  PDFJS.GlobalWorkerOptions.workerSrc =
    "https://unpkg.com/pdfjs-dist@4.9.155/build/pdf.worker.min.mjs";

  useEffect(() => {
    setFields(props.fields ?? []);
  }, [props.fields]);
  useEffect(() => {
    let dic = {};
    signers.forEach((x) => {
      dic[x.value] = x.label;
    });
    setSignersDic(dic);
  }, [signers]);

  useEffect(() => {
    if (selectedField) setShowEditField(true);
    else setShowEditField(false);
  }, [selectedField]);

  useEffect(() => {
    if (!props.filePath || props.filePath.length === 0) {
      setPdfRef(undefined);
      return;
    }
    const fullUrl = StoreX.BuildUrl(`../File/Form/${props.formId}`);
    const loadingTask = PDFJS.getDocument(fullUrl);
    loadingTask.promise.then(
      (loadedPdf) => {
        setPdfRef(loadedPdf);
      },
      function (reason) {
        console.error(reason);
      }
    );
  }, [props.filePath]);

  useEffect(() => {
    if (!form?.NewFilePath) {
      setPdfRefNew(undefined);
      return;
    }
    const fullUrl = StoreX.BuildUrl(`../File/Form/${props.formId}?new=1`);
    const loadingTask = PDFJS.getDocument(fullUrl);
    loadingTask.promise.then(
      (loadedPdf) => {
        setPdfRefNew(loadedPdf);
      },
      function (reason) {
        console.error(reason);
      }
    );
  }, [props.form.NewFilePath]);

  const readPdf = useCallback((pdf = pdfRef) => {}, [props.filePath]);

  const signersChanged = (value: Selectable) => {
    let signer = signers.find((x) => x.value === value.value);
    if (!signer) {
      value.value = uid();
      setSigners([...signers, value]);
    }

    let id = `${value.value}`;

    setSelectedField({ ...selectedField, SignatureOwner: id });
  };

  const pullFields = useCallback(() => {
    let fields2: IFieldInfo[] = [];
    setFieldsPulled(true);
    if (pdfRef) {
      let pages = pdfRef.numPages;
      for (let i = 0; i < pages; i++) {
        pdfRef.getPage(i + 1).then(function (page) {
          page.getAnnotations().then((items) => {
            items.forEach((x) => {
              //if (x.hasAppearance && x.fieldType) {
              if (x.fieldType) {
                fields2.push({
                  Label: x.fieldName,
                  FormKey: x.fieldName,
                  Type: x.fieldType,
                  PositionRect: x.rect,
                  PublicId: uid(),
                  InternalKey: ""
                });
              } else {
                console.log("Item not shown", x);
              }
            });
            setFields([...fields2]);
          });
        });
      }
    }
  }, [pdfRef]);

  const pullFieldsNew = useCallback(() => {
    let fields2: IFieldInfo[] = [];
    setFieldsPulled(true);
    if (pdfRefNew) {
      let pages = pdfRefNew.numPages;
      for (let i = 0; i < pages; i++) {
        pdfRefNew.getPage(i + 1).then(function (page) {
          page.getAnnotations().then((items) => {
            items.forEach((x) => {
              if (x.fieldType) {
                fields2.push({
                  Label: x.fieldName,
                  FormKey: x.fieldName,
                  Type: x.fieldType,
                  PositionRect: x.rect,
                  PublicId: uid(),
                  InternalKey: ""
                });
              } else {
                console.log(`Item not shown. page: ${i+1}`, x);
              }
            });
            setNewFields([...fields2]);
          });
        });
      }
    }
  }, [pdfRefNew]);

  const updateFields = () => {
    props.fieldsUpdated([...fields], signers);
    setFieldsPulled(false);
  };

  const saveField = (close: boolean) => {
    let cFields = [...fields];
    for (let i = 0; i < cFields.length; i++) {
      let f = cFields[i];
      if (f.PublicId === selectedField?.PublicId) {
        cFields[i] = { ...selectedField };
        props.fieldsUpdated(cFields, signers);
        break;
      }
    }

    if (close) setSelectedField(undefined);
  };

  const renderField = (field: IFieldInfo) => {
    let isSignature = field.SignatureOwner ? true : false;
    let type = "";
    switch (field.Type) {
      case "Tx":
        type = "Textbox";
        break;
      case "Btn":
        type = "Checkbox";
        break;
      default:
        type = "Unknown";
        break;
    }

    return (
      <>
        <div className="flex-between">
          <div>
            <span
              className="click"
              title="Click to edit this field"
              onClick={() => {
                setSelectedField(field);
              }}
            >
              <Icon type={IconType.edit} />
            </span>
            <strong>{field.Label} </strong>
          </div>
          <div className="text-right">
            {field.Hidden && (
              <span title="Hidden">
                <Icon type={IconType.eyeNo} />
              </span>
            )}
            {isSignature && (
              <div>
                <div>
                  {field.AllowOwnerToEdit && (
                    <Icon
                      type={IconType.edit}
                      title="owner can edit"
                      addClass="text-success"
                    />
                  )}
                  <Icon type={IconType.sign} /> {field.SignatureType}
                </div>
                <div className="text-muted">
                  {signersDic[field.SignatureOwner]}
                </div>
              </div>
            )}
            {!isSignature && (
              <div className="text-right">
                <div>{type}</div>
                {field.InternalKey && (
                  <div className="text-muted">{field.InternalKey}</div>
                )}
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  const pdfFieldSelected = (field: any) => {
    console.warn('Hello');
    let f = fields.find(x=>x.Label == field.fieldName);
    if(!f) f = fields.find(x=> x.FormKey == field.fieldName);
    console.log(field, f);
    setOldField(f);

    mapNewFields(newField, f);
  };

  const pdfNewFieldSelected = (field: any) => {
    console.warn('Hellox');
    let f = newfields.find(x=>x.Label == field.fieldName);
    console.log(field,f);
    setNewField(f);
    mapNewFields(f, oldField);
  };

  const mapNewFields=(nf:IFieldInfo|undefined, of:IFieldInfo|undefined)=>{
    if(nf && of){
      let x:IFieldInfo = {...of};
      x.PositionRect = nf.PositionRect;
      x.FormKey = nf.FormKey;
      x.Type = nf.Type;
      x.Mapped = true;


      let fs = newFieldsToSave.filter(r=>x.PublicId !== r.PublicId);
      fs.push(x);
      setNewFieldsToSave(fs.sort((a,b)=>{return a.Label > b.Label ? 1:-1;}));
      setNewField(undefined);
      setOldField(undefined);
      toast.info('Field Mapped');
      console.log('Field to Save', fs);
    }
  };

  const removeMapping = (mapped:IFieldInfo) =>{
    let mappedFields = newFieldsToSave.filter(x=>x.PublicId !== mapped?.PublicId);
    setNewFieldsToSave(mappedFields);
  };

  const saveMappedFields = ()=>{
    let nf = [...newFieldsToSave];
    let dic:Record<string,IFieldInfo> = {};
    nf.forEach(x=>{
      if(x.FormKey) dic[x.FormKey] = x;
    })
    
    let notMapped = newfields.filter(x=>!dic[x.FormKey ?? '']);
    notMapped.forEach(x=>{
      x.Label = `*NEW* ${x.Label}`;
      nf.push(x);
    });

    StoreX.instance.server
    .postApi<ServerResponse<IFormDetail>>(`../GenericForms/MapToNewFields`,{...form, Fields:nf})
    .then((x) => {
      if (x.Success) {
        toast.success(`Saved, You must refresh.`, {
          autoClose: false,
        });
      } else {
        toast.error(`Error saving Form. ${x.Message}`, {
          autoClose: false,
        });
      }
    });
  };

  return (
    <>
      <div className="flex-between">
        <h4>Fields</h4>
        <div>
          {props.filePath && pdfRef && !fieldsPulled && (<>
            <button
              type="button"
              className="btn btn-default"
              onClick={pullFields}
            >
              <Icon type={IconType.fileInvoice} /> pull fields from form
            </button>

            
          { form && form.NewFilePath && pdfRefNew && <>
            <button type="button" className="btn btn-default" onClick={()=>{pullFieldsNew();setShowGoBig(true);}}>open</button>
            </>
          }</>
          )}
          {fieldsPulled && (
            <button
              type="button"
              className="btn btn-default"
              onClick={updateFields}
            >
              <Icon type={IconType.fileInvoice} /> save fields
            </button>
          )}
        </div>
      </div>
      {fields && fields.length > 0 && (
        <div>
          <div className="form-manager-field-list">
            {fields.map((x, i) => {
              return (
                <div key={`field-map-${i}`} className="field-tile">
                  {renderField(x)}
                </div>
              );
            })}
          </div>

          { showGoBig && form && form.NewFilePath && (
            <div className="form-manager-go-big">
              <button type="button" className="btn btn-xs" onClick={()=>{setShowGoBig(false)}}>close</button>
              
              <div className="flex-between">
                <div>
                  <h4>Old File</h4>
                  <PdfViewer
                    source={`../File/Form/${form.PublicId}`}
                    id={form.PublicId}
                    close={undefined}
                    updateValues={undefined}
                    values={{}}
                    fieldMetaData={form.Fields}
                    signer={undefined}
                    signRequested={undefined}
                    downloadFormUrl={undefined}
                    isSetupMode={true}
                    fieldSelected={pdfFieldSelected}
                  />
                </div>
                <div className="field-maper">
                  <h4>Fields Needing to be Mapped</h4>
                  {oldField && <div>
                      {oldField.Label}
                    </div>}
                <h4>Map Fields</h4>
                {newfields.map((x,i)=>{
                  let selected = newField?.PublicId === x.PublicId;
                  let mapped = newFieldsToSave.find(r=>r.FormKey === x.FormKey);
                  return <div 
                  className={`field-to-map ${selected ? 'sel':''} ${mapped?'map':''}`} 
                    key={`ftm-${i}`}>
                    {x.Label} {mapped && <button type="button" className="btn btn-default btn-xs" onClick={()=>{removeMapping(mapped)}}>remove</button>}
                  </div>
                })}

                <h4>
                  <button type="button" className="btn btn-secondary" onClick={()=>{saveMappedFields()}}><Icon type={IconType.save} />Map to new file</button>
                </h4>
                </div>
                <div>
                  <h4>New File</h4>
                  <PdfViewer
                    source={`../File/Form/${form.PublicId}?new=1`}
                    id={form.PublicId}
                    close={undefined}
                    updateValues={undefined}
                    values={{}}
                    fieldMetaData={newfields}
                    signer={undefined}
                    signRequested={undefined}
                    downloadFormUrl={undefined}
                    isSetupMode={true}
                    fieldSelected={pdfNewFieldSelected}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {!fields ||
        (fields.length === 0 && (
          <div className="form-manager-field-list">No Fields</div>
        ))}

      {showEditField && (
        <Modal setModalOpen={setShowEditField} title={`Edit Field`} size={"l"}>
          <div>
            <div className="form-horizontal">
              <div className="form-group">
                <label
                  htmlFor="form-publicId"
                  className="col-sm-4 control-label"
                >
                  Id
                </label>
                <div className="col-sm-8">
                  <input
                    id="form-publicId"
                    type="textbox"
                    readOnly={true}
                    maxLength={500}
                    className="form-control"
                    value={selectedField?.PublicId}
                  />
                </div>
              </div>
              <div className="form-group">
                <label
                  htmlFor="form-form-key"
                  className="col-sm-4 control-label"
                >
                  Form Key
                </label>
                <div className="col-sm-8">
                  <input
                    id="form-form-key"
                    type="textbox"
                    readOnly={true}
                    maxLength={500}
                    className="form-control"
                    value={selectedField?.FormKey}
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="form-label" className="col-sm-4 control-label">
                  Label
                </label>
                <div className="col-sm-8">
                  <input
                    id="form-label"
                    type="textbox"
                    maxLength={500}
                    className="form-control"
                    value={selectedField?.Label}
                    onChange={(e) => {
                      if (selectedField)
                        setSelectedField({
                          ...selectedField,
                          Label: e.target.value,
                        });
                    }}
                  />
                </div>
              </div>
              <div className="form-group">
                <label
                  htmlFor="form-internal-key"
                  className="col-sm-4 control-label"
                >
                  Fill From
                </label>
                <div className="col-sm-8">
                  <select
                    id="form-internal-key"
                    className="form-control"
                    value={selectedField?.InternalKey ?? "NS"}
                    onChange={(e) => {
                      if (selectedField)
                        setSelectedField({
                          ...selectedField,
                          InternalKey: e.target.value,
                        });
                    }}
                  >
                    <option value="NS">Not set</option>
                    <option value="ParticipantName">Participant Name</option>
                    <option value="ParticipantEmail">Participant Email</option>
                    <option value="ParticipantPhone">Participant Phone</option>
                    <option value="ParticipantGrade">Grade</option>
                    <option value="EntryTitle">Title</option>
                    <option value="EntryCategory">Category</option>
                    <option value="SchoolName">School</option>
                    <option value="SchoolAddress">School Address</option>
                    <option value="SchoolAddress2">School Address 2</option>
                    <option value="SchoolCityStateZip">
                      School City, State Zip
                    </option>
                    <option value="SchoolPhone">School City, State Zip</option>
                  </select>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="form-regex" className="col-sm-4 control-label">
                  Validation Regex
                </label>
                <div className="col-sm-8">
                  <select
                    id="form-regex"
                    className="form-control"
                    value={selectedField?.ValidationRegex ?? "NS"}
                    onChange={(e) => {
                      if (selectedField)
                        setSelectedField({
                          ...selectedField,
                          ValidationRegex: e.target.value,
                        });
                    }}
                  >
                    <option value="NS">Not set</option>
                    <option value="Phone">Phone</option>
                    <option value="Email">Email</option>
                    <option value="TIN">TIN / SSN</option>
                  </select>
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="form-max" className="col-sm-4 control-label">
                  Max Length
                </label>
                <div className="col-sm-8">
                  <input
                    id="form-max"
                    type="number"
                    min={0}
                    maxLength={20000}
                    className="form-control"
                    value={selectedField?.MaxLength ?? ""}
                    onChange={(e) => {
                      if (selectedField)
                        setSelectedField({
                          ...selectedField,
                          MaxLength: +e.target.value,
                        });
                    }}
                  />
                  <span className="label label-info">Use 0 for no limit</span>
                </div>
              </div>
              <div className="col-sm-8 col-sm-offset-4">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    if (selectedField)
                      setSelectedField({
                        ...selectedField,
                        Hidden: e.target.checked,
                      });
                  }}
                  id="form-hidden"
                  checked={selectedField?.Hidden ?? false}
                />
                <label htmlFor="form-hidden" className="control-label">
                  Field is hidden.
                </label>
              </div>

              <h3>Signature</h3>
              <div className="form-group">
                <label
                  htmlFor="form-signature-owner"
                  className="col-sm-4 control-label"
                >
                  Signature Owner
                </label>
                <div className="col-sm-8">
                  <Creatable
                    onChange={signersChanged}
                    value={signers?.filter(
                      (x) => x.value === selectedField?.SignatureOwner
                    )}
                    options={signers}
                    placeholder={"Signer"}
                    className=""
                  />
                  {selectedField?.SignatureOwner && (
                    <div>
                      <button
                        type="button"
                        className="btn btn-xs btn-danger"
                        onClick={() => {
                          if (selectedField)
                            setSelectedField({
                              ...selectedField,
                              SignatureOwner: null,
                            });
                        }}
                      >
                        remove
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label
                  htmlFor="form-signature-type"
                  className="col-sm-4 control-label"
                >
                  Signature Type
                </label>
                <div className="col-sm-8">
                  <select
                    id="form-signature-type"
                    className="form-control"
                    value={selectedField?.SignatureType ?? "NA"}
                    onChange={(e) => {
                      if (selectedField)
                        setSelectedField({
                          ...selectedField,
                          SignatureType: e.target.value,
                        });
                    }}
                  >
                    <option value="NA">Not Applicable</option>
                    <option value="Signature">Signature</option>
                    <option value="Initials">Initials</option>
                    <option value="DateSigned">Date Signed</option>
                    <option value="Name">Name</option>
                    <option value="Title">Title</option>
                    <option value="Email">Email</option>
                    <option value="Phone">Phone</option>
                    <option value="PhoneEmail">Phone / Email</option>
                    <option value="Text">Text</option>
                  </select>
                </div>
              </div>
              {(!selectedField?.SignatureType ||
                selectedField?.SignatureType !== "NA") && (
                <div className="form-group">
                  <div className="col-sm-8 col-sm-offset-4">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        if (selectedField)
                          setSelectedField({
                            ...selectedField,
                            AllowOwnerToEdit: e.target.checked,
                          });
                      }}
                      id="form-signature-allow-owner"
                      checked={selectedField?.AllowOwnerToEdit ?? false}
                    />
                    <label
                      htmlFor="form-signature-allow-owner"
                      className="control-label"
                    >
                      Allow owner to edit field, not just signer.
                    </label>
                  </div>
                </div>
              )}
              <div className="form-group">
                <div className="headroom-xl col-sm-12" />
                <div className="headroom-xl col-sm-12" />
                <div className="headroom-xl col-sm-12" />
              </div>

              <div className="form-group">
                <div className="col-sm-8 col-sm-offset-4">
                  <button
                    type="button"
                    className="btn btn-secondary save-close-btn"
                    onClick={() => {
                      saveField(true);
                    }}
                  >
                    <Icon type={IconType.save} /> Save & Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={() => {
                      saveField(false);
                    }}
                  >
                    <Icon type={IconType.save} /> Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={() => {
                      setSelectedField(undefined);
                    }}
                  >
                    <Icon type={IconType.close} /> close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default FieldsManager;
