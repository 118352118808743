import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { IConfigBase, IConfigChild, IConfigGroup } from "../../ObjectTypes/SetupTypes";
import StoreX, { store } from "../../redux/oldStore";
import { ServerResponse } from "../../utils/Server";
import SetupConfig from "./SetupConfig";
import { ISetupWizardResponse } from "./SetupTypes";
import SetupWizard from "./SetupWizard";
import { ValueChange } from "./SetupTypes";
import { IFileUpload } from "../FormFromApiData/FormModal";
import redux, { IState, ReduxMap } from "../../redux/redux";
import Button from "../_Core/Button";
import FileDownload from 'js-file-download';

export interface ISetupAllProps extends IState {}

interface IForm{
  text?:string;
}

const SetupAll = (props: ISetupAllProps) => {
  const [data, setData] = useState<ISetupWizardResponse>();
  const [wizard, setWizard] = useState<IConfigGroup>();
  const [filteredConfigs, setFilteredConfigs] = useState<IConfigBase[]>([]);
  //const [changedValues, setChangedValues] = useState<ValueChange[]>([]);
  const [filter, setFilter] = useState<IForm>({});
  const [files, setFiles] = useState<IFileUpload[]>([]);

  useEffect(() => {
   init();
   let search = StoreX.GetQueryStringValue('search') ?? StoreX.GetQueryStringValue('s') ;
   if(search) setFilter({...filter,text:search});
  }, []);

  useEffect(() => {
    let c = data?.Configs ?? [];

    if (filter.text) {
      let s = filter.text.toLowerCase();
      c = c.filter((x) =>
        x.Name.toLowerCase().indexOf(s) > -1
        || x.PublicId.toLowerCase().indexOf(s) > -1
        || x.DataType?.toLowerCase() === s
      );
    }

    setFilteredConfigs(c);
  }, [data, data?.Configs, filter]);

  const init = () =>{
    store.server
    .getApi<ServerResponse<ISetupWizardResponse>>(`../SetupWiz/Wizards`)
    .then((x) => {
      if (x.Success) {
        setData(x.Value);
      } else {
        toast.error(`Error getting setup wizards. ${x.Message}`, {
          autoClose: false,
        });
      }
    });
  };

  const downloadSettings = () => {
    toast.info('Downloading settings...');

    let dataExport:any = {
      EventName: props.SettingsResponse?.Name,
      EventId: props.SettingsResponse?.EventId,
      ClientId: props.SettingsResponse?.ClientId,
      Url: props.SettingsResponse?.Url,
      Version: props.SettingsResponse?.Version,
      UserName: props.User?.username,
      UserId: props.User?.id,
      ConfigExportVersion: '0.1',
      Configs:[],
    };

    data?.Configs.forEach(c=>{
      dataExport.Configs.push({
        Name: c.Name,
        Value: c.Value,
        PublicId: c.PublicId,
        Key: c.Key,
        Type: c.Type,
        DataType: c.DataType,        
        ConfigType: c.ConfigType,
      });
    });

       
    FileDownload(JSON.stringify(dataExport), `settings.json`);
  };


  const canView2 = (toSearch: IConfigChild[], search:IForm)=>{
    if(search.text){
        let fullTerm = search.text.toLowerCase();
        let terms = fullTerm.split(/[,\s]/g);
        let fakeTerms:Record<string,boolean>={};
        fakeTerms['i'] = true;
        fakeTerms['a'] = true;
        fakeTerms['the'] = true;
        fakeTerms['is'] = true;
        fakeTerms['it'] = true;
        fakeTerms['of'] = true;
        fakeTerms['as'] = true;
        fakeTerms['for'] = true;
        fakeTerms['your'] = true;
        fakeTerms['to'] = true;
        fakeTerms['or'] = true;

        terms = terms.filter(x=>!fakeTerms[x]);

        console.log(terms);
        let termsDic:Record<string,boolean> = {};
        terms.forEach(x=>{termsDic[x] = true;});

        let branchDic:Record<string,number> = {};

        toSearch.forEach(b=>{
            let score = 0;

            let sText = b.Description?.toLowerCase() ?? '';
            let sTitle = b.Name?.toLowerCase() ?? '';
            let tags = b.Tags?.map(x=>x.toLowerCase()) ?? [];

            if(b.PublicId.toLowerCase() === fullTerm) score += 10000;                
            if(sTitle === fullTerm) score += 100;

            if(sText.indexOf(fullTerm) > -1) score += 15;
            if(sTitle.indexOf(fullTerm) > -1) score += 30;

            terms.forEach(term=>{
                if(sText.indexOf(term) > -1) score += 3;
                if(sTitle.indexOf(term) > -1) score += 3;

                tags.forEach(t=>{
                    if(t.indexOf(term) > -1) score += 2;
                    if(t === term) score += 10;
                })
            });

            branchDic[b.PublicId] = score;
        });

        console.log(branchDic);
        return toSearch.filter(x=>branchDic[x.PublicId] > 4).sort((a,b)=>{return branchDic[a.PublicId] > branchDic[b.PublicId] ? -1:1;});

    }

    return toSearch;
}


  const startWizard = (w: IConfigGroup) => {
    setWizard(w);
  };

  const closeWizard = () => {
    setWizard(undefined);
  };

  const onSave = () => {
    redux.Reload();
  };

  const save = (value: ValueChange, file: File | undefined) => {
    // let values = changedValues;
    // if (values.length === 0) {
    //   toast.info("No changeds need to be saved.");
    //   return;
    // }

    let myFiles = file ? [file] : files.map(x => x.file);
    store.server
      .postApi<ServerResponse<any>>(`../SetupWiz/Save`, { values: [value] }, myFiles)
      .then((x) => {
        if (x.Success) {
          toast.success("Changes saved.");
          if (data) setData({ ...data, Configs: x.Value.Configs ?? [] });
          //setChangedValues([]);
          setFiles([]);
          onSave();
        } else {
          toast.error(x.Message, { autoClose: false });
        }
      });
  };

  const valueUpdated = (publicId: string, value: any) => {
    // let values = changedValues.filter((x) => x.PublicId !== publicId);
    // values.push({ PublicId: publicId, Value: value });
    //setChangedValues(values);
    save({ PublicId: publicId, Value: value }, undefined);
  };

  const valueFileUpdated = (publicId: string, value: any, file?: File) => {
    //let values = changedValues.filter((x) => x.PublicId !== publicId);
    //values.push({ PublicId: publicId, Value: file?.name ?? value ?? '' }); 

    let fs = files.filter(x => x.Key !== publicId);
    if (file) fs.push({ file: file, Key: publicId });
    setFiles(fs);
    //setChangedValues(values);    
    save({ PublicId: publicId, Value: value }, file);
  };



  return (
    <>
      <div className="setup-search-container">
        <div className="form-horizontal">
          <div className="form-group background-white">
            <label
              className="col-sm-3 control-label"
              htmlFor="wiz-search"
            >

              Search:
            </label>
            <div className="col-sm-5" id="search-bar-input">
              <input
                id="wiz-search"
                type="text"
                autoFocus
                maxLength={1000}
                className="form-control"
                placeholder="Search all settings"
                value={filter.text}
                onChange={(e) => {
                  setFilter({ ...filter, text: e.target.value });
                }}
              />
            </div>
            <div className="col-sm-4">
                <Button type='download' text="Export" onClick={downloadSettings} />
            </div>
          </div>
        </div>
      </div>
      <div className="">
        {!wizard && (
          <>
                        <div>
              <div className="wiz-config-list-container">
                {filteredConfigs.map((c, i) => {
                  return (
                    <SetupConfig
                      key={`1sg-${i}`}
                      Config={c}
                      valueUpdated={valueUpdated}
                      valueFileUpdated={valueFileUpdated}
                      minimize={true}
                      isOwner={data?.IsOwner ?? false}
                    />
                  );
                })}
              </div>
              <div>
              </div>
            </div>
          </>
        )}
        {wizard && (
          <div>
            <SetupWizard wizardKey={wizard.Key} closeWizard={closeWizard} onSave={onSave} />
          </div>
        )}
      </div>
    </>
  );
};

export default ReduxMap(SetupAll);
