import { useState, useEffect } from 'react';
import { IConfigBase } from '../../ObjectTypes/SetupTypes';
import StoreX from '../../redux/oldStore';
import { ValueTruthy } from '../../utils/Tools';
import Editor from '../GenericForms/Editor';
import InputGradeRange from '../GenericForms/InputGradeRange';
import Icon, { IconType } from '../Icon/Icon';
import Modal from '../Modal/Modal';
import { EasyFileInput } from '../Tools/ExpandableDiv';
import { HtmlEditorPro2 } from '../Tools/htmlEditor2';
import ConfigFormEditor from './ConfigForm/ConfigFormEditor';
import ConfigFormLanding from './ConfigForm/ConfigFormLanding';
import { IConfigItemSummary } from './ConfigForm/ConfigFormTypes';

interface ISetupConfigProps {
  valueUpdated: Function;
  valueFileUpdated?: Function;
  Config: IConfigBase;
  minimize?: boolean;
  isOwner?: boolean;
}

const SetupConfig = (props: ISetupConfigProps) => {
  const [startValue, setStartValue] = useState<any>();
  const [value, setValue] = useState<any>();
  const [showMore, setShowMore] = useState<boolean>();
  const [showEditor, setShowEditor] = useState<boolean>(false);
  const [enabled, setEnabled] = useState<boolean>(true);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<IConfigItemSummary>();
  const [showConfigEditorModal, setShowConfigEditorModal] = useState<boolean>(false);

  useEffect(() => {
    if (props.Config) {
      let tempValue: any = props.Config.Value;
      if (props.Config.ConfigType === 'bit-bool' || props.Config.ConfigType === 'bit-bool-not') {
        tempValue = tempValue?.toLowerCase() === 'true';
      }
      setValue(tempValue);
      setStartValue(tempValue);
      setEnabled(props.Config.UserRoleAccess?.toLowerCase() !== 'zfairs' || (props.isOwner ?? false));
    }
  }, [props.Config]);

  useEffect(() => {
    if (startValue === value) {
      return;
    }
    if (
      props.Config.ConfigType === 'bit-bool' ||
      props.Config.ConfigType === 'bit-bool-not' ||
      props.Config.ConfigType === 'bit-char' ||
      props.Config.ConfigType === 'bit-string' ||
      props.Config.DataType == 'bool' ||
      props.Config.DataType == 'html-full' ||
      props.Config.Key === 'Grade.Fair'
      //todo: why is this not always true??? html types what about just strings...
    ) {
      console.log('values changed');
      props.valueUpdated(props.Config.PublicId, value);
      setStartValue(value);
    }
  }, [value]);

  const updateOnBlur = () => {
    console.log("UpdateOnBlur Called");
      if (startValue === value) return;
      if (props.Config.Required && !value) {
        setValue(startValue);
        return;
      }
      props.valueUpdated(props.Config.PublicId, value);
      setStartValue(value);
  };
  const updateOnBlur2 = (xValue: any) => {
      if (startValue === xValue) return;
      if (props.Config.Required && !xValue) {
        setValue(startValue);
        return;
      }
      props.valueUpdated(props.Config.PublicId, xValue);
      setStartValue(xValue);
  };

  const GetContestManagementType = () => {
    return (
      <>
        <div className="flex">
          <select
            id={props.Config.PublicId}
            value={value ?? '0'}
            className="form-control contest-mgt-type"
            disabled={!enabled}
            onChange={(e) => {
              setValue(e.target.value);
            }}>
            <option value={'0'}>Science Fair</option>
            <option value={'3'}>Invention Contest</option>
            <option value={'9'}>Film Festival</option>
            <option value={'2'}>Reflections</option>
            <option value={'7'}>Art Fair</option>
            <option value={'4'}>Seal of Biliteracy</option>
            <option value={'1'}>History Contest</option>
            <option value={'5'}>AcaDeca</option>
            <option value={'6'}>Spelling Bee</option>
            <option value={'8'}>Teacher Innovator Awards</option>
            <option value={'Z'}>Other</option>
          </select>
          <label className="flex" htmlFor={props.Config.PublicId}>
            {props.Config.Name} {zFairsOnlyLabel(props.Config)}
          </label>
        </div>
        {props.Config.Description && (
          <span
            title="more info"
            className="click"
            onClick={() => {
              setShowMore(true);
            }}>
            <Icon type={IconType.info} />
          </span>
        )}
      </>
    );
  };

  const buildSetting = () => {
    if (props.Config.ConfigType === 'complex') {
      return GetComplexType(props.Config);
    }
    return <div className="config-group-input">{buildSettingOuter()}</div>;
  };

  const buildSettingOuter = () => {
    switch (props.Config.ConfigType) {
      case 'bit-bool-not':
      case 'bit-bool':
        return (
          <>
            <label
              className={'flex ' + ` config-bool id-${props.Config.PublicId}`}
              id={props.Config.PublicId}
              htmlFor={props.Config.PublicId}
              onClick={() => {
                if (enabled) setValue(value ? false : true);
              }}>
              <Icon type={value ? IconType.on : IconType.off} addClass={`click config-switch ${value ? 'text-info' : ''}`} title="click to toggle on or off" />
              {props.Config.Name}{props.Config.Required && <>*</>} {zFairsOnlyLabel(props.Config)}
              <span className='bumper'></span>
              {value ? <span className='label label-info'>On</span>:<span className='label label-default'>Off</span>}
            </label>
            {props.Config.Description && (
              <span
                title="more info"
                className="click"
                onClick={() => {
                  setShowMore(true);
                }}>
                <Icon type={IconType.info} />
              </span>
            )}
          </>
        );
        break;
      case 'bit-char':
        return (
          <>
            <div className="flex grow wrap">
              <select
                id={props.Config.PublicId}
                value={value ?? '0'}
                className={'form-control hide-ask-require ' + ` config-hide-ask-require id-${props.Config.PublicId} ${props.Config.Required ? 'x-required' : ''}`}
                disabled={!enabled}
                onChange={(e) => {
                  setValue(e.target.value);
                }}>
                <option value={'0'}>Hidden</option>
                <option value={'1'}>Asked</option>
                <option value={'2'}>Required</option>
              </select>
              <label className="" htmlFor={props.Config.PublicId}>
                {props.Config.Name}{props.Config.Required && <>*</>} {zFairsOnlyLabel(props.Config)}
              </label>
            </div>
            {props.Config.Description && (
              <span
                title="more info"
                className="click"
                onClick={() => {
                  setShowMore(true);
                }}>
                <Icon type={IconType.info} />
              </span>
            )}
          </>
        );
        break;
      case 'fairSetting':
        return GetInputType(props.Config);
        break;
      // case "bit-string":
      //     return GetContestManagementType();
      //   break;
      case 'bit-string':
      case 'table-column':
      case 'session':
      case 'fairBigText':
        return GetInputType(props.Config);
        break;
      case 'complex':
        return GetComplexType(props.Config);
        break;
      case 'route-to':
        return GetRouteTo(props.Config);
        break;
      default:
        return (
          <>
            Unknown Config Type: {props.Config.ConfigType} {props.Config.DataType} {props.Config.Name} {props.Config.PublicId}
          </>
        );
        break;
    }
  };

  const maxLength = (maxLength: number | undefined | null, defaultNumber: number) => {
    if (maxLength === 0 || maxLength === undefined || maxLength === null || !maxLength || maxLength < 1) return defaultNumber;
    return maxLength;
  };
  const GetRouteTo = (config: IConfigBase) => {
    return <a className='btn btn-default' href={StoreX.BuildUrlWithFair(config.RouteTo ?? '')} target={config.ToNewTab ? '_blank': '_self'}>{config.ButtonName ?? config.RouteTo ?? 'Go to route missing...'}</a>
  }

  const GetInputType = (config: IConfigBase) => {
    switch (props.Config.DataType ?? 'text') {
      case 'string':
        return (
          <>
            <div className="flex-between w-100">
              <label htmlFor={config.PublicId} className="control-label" dangerouslySetInnerHTML={{ __html: props.Config.Name + (props.Config.Required ? "*":'') }} />
              {zFairsOnlyLabel(props.Config)}
              {config.Description && (
                <span
                  title="more info"
                  className="click"
                  onClick={() => {
                    setShowMore(true);
                  }}>
                  <Icon type={IconType.info} />
                </span>
              )}
            </div>
            <input
              type="text"
              id={config.PublicId}
              className={'form-control' + ` config-text id-${props.Config.PublicId} ${props.Config.Required ? 'x-required' : ''}`}
              maxLength={maxLength(props.Config.MaxLength, 250)}
              value={value ?? ''}
              onBlur={()=>{updateOnBlur()}}
              disabled={!enabled}
              onChange={(e) => {
                setValue(e.target.value);
              }}
            />
          </>
        );
        break;
      case 'password':
        return (
          <>
            <div className="flex-between w-100">
              <label htmlFor={config.PublicId} className="control-label" dangerouslySetInnerHTML={{ __html: props.Config.Name + (props.Config.Required ? "*":'') }} />
              {zFairsOnlyLabel(props.Config)}
              {config.Description && (
                <span
                  title="more info"
                  className="click"
                  onClick={() => {
                    setShowMore(true);
                  }}>
                  <Icon type={IconType.info} />
                </span>
              )}
            </div>
            <input
              type="password"
              id={config.PublicId}
              className={'form-control' + ` config-pass id-${props.Config.PublicId} ${props.Config.Required ? 'x-required' : ''}`}
              maxLength={maxLength(props.Config.MaxLength, 250)}
              value={value ?? ''}
              onBlur={()=>{updateOnBlur()}}
              disabled={!enabled}
              onChange={(e) => {
                setValue(e.target.value);
              }}
            />
          </>
        );
        break;
      case 'number':
        return (
          <>
            <div className="flex-between w-100">
              <label htmlFor={config.PublicId} className="control-label">
                {config.Name}{props.Config.Required && <>*</>} {zFairsOnlyLabel(props.Config)}
              </label>

              {config.Description && (
                <span
                  title="more info"
                  className="click"
                  onClick={() => {
                    setShowMore(true);
                  }}>
                  <Icon type={IconType.info} />
                </span>
              )}
            </div>
            <input
              type="number"
              id={config.PublicId}
              className={'form-control' + ` config-number id-${props.Config.PublicId} ${props.Config.Required ? 'x-required' : ''}`}
              value={value ?? ''}
              onBlur={()=>{updateOnBlur()}}
              disabled={!enabled}
              onChange={(e) => {
                setValue(e.target.value);
              }}
            />
          </>
        );
        break;
      case 'dropdown':
        return (
          <>
            <div className="flex-between w-100">
              <label htmlFor={config.PublicId} className="control-label">
                {config.Name}{props.Config.Required && <>*</>} {zFairsOnlyLabel(props.Config)}
              </label>

              {config.Description && (
                <span
                  title="more info"
                  className="click"
                  onClick={() => {
                    setShowMore(true);
                  }}>
                  <Icon type={IconType.info} />
                </span>
              )}
            </div>
            <select
              id={config.PublicId}
              className={'form-control' + ` config-dropdown id-${props.Config.PublicId} ${props.Config.Required ? 'x-required' : ''}`}
              value={value}
              disabled={!enabled}
              onChange={(e) => {
                setValue(e.target.value);
                updateOnBlur2(e.target.value);
              }}>
              <option value={''}>select</option>
              {props.Config.ValueOptions?.map((x, i) => {
                return (
                  <option key={`id-${props.Config.PublicId}-${i}`} value={x.Value}>
                    {x.Name}
                  </option>
                );
              })}
            </select>
          </>
        );
        break;
      case 'dateTime':
        return (
          <>
            <div className="flex-between w-100">
              <label htmlFor={config.PublicId} className="control-label">
                {config.Name}{props.Config.Required && <>*</>} {zFairsOnlyLabel(props.Config)}
              </label>

              {config.Description && (
                <span
                  title="more info"
                  className="click"
                  onClick={() => {
                    setShowMore(true);
                  }}>
                  <Icon type={IconType.info} />
                </span>
              )}
            </div>
            <input
              type="datetime-local"
              id={config.PublicId}
              className={'form-control' + ` config-datetime id-${props.Config.PublicId} ${props.Config.Required ? 'x-required' : ''}`}
              value={value ?? ''}
              onBlur={()=>{updateOnBlur()}}
              disabled={!enabled}
              onChange={(e) => {
                setValue(e.target.value);
              }}
            />
          </>
        );
        break;
      case 'date':
        return (
          <>
            <div className="flex-between w-100">
              <label htmlFor={config.PublicId} className="control-label">
                {config.Name}{props.Config.Required && <>*</>} {zFairsOnlyLabel(props.Config)}
              </label>

              {config.Description && (
                <span
                  title="more info"
                  className="click"
                  onClick={() => {
                    setShowMore(true);
                  }}>
                  <Icon type={IconType.info} />
                </span>
              )}
            </div>
            <input
              type="date"
              id={config.PublicId}
              className={'form-control' + ` config-date id-${props.Config.PublicId} ${props.Config.Required ? 'x-required' : ''}`}
              value={value}
              onBlur={()=>{updateOnBlur()}}
              disabled={!enabled}
              onChange={(e) => {
                setValue(e.target.value);
              }}
            />
          </>
        );
        break;
      case 'time':
        return (
          <>
            <div className="flex-between w-100">
              <label htmlFor={config.PublicId} className="control-label">
                {config.Name}{props.Config.Required && <>*</>} {zFairsOnlyLabel(props.Config)}
              </label>

              {config.Description && (
                <span
                  title="more info"
                  className="click"
                  onClick={() => {
                    setShowMore(true);
                  }}>
                  <Icon type={IconType.info} />
                </span>
              )}
            </div>
            <input
              type="time"
              id={config.PublicId}
              className={'form-control' + ` config-time id-${props.Config.PublicId} ${props.Config.Required ? 'x-required' : ''}`}
              value={value}
              onBlur={()=>{updateOnBlur()}}
              disabled={!enabled}
              onChange={(e) => {
                setValue(e.target.value);
              }}
            />
          </>
        );
        break;
      case 'bool':
        return (
          <>
            <label
              className={'flex' + ` config-bool id-${props.Config.PublicId} ${props.Config.Required ? 'x-required' : ''}`}
              htmlFor={config.PublicId}
              onClick={() => {
                if (enabled) setValue(ValueTruthy(value) ? false : true);
              }}>
              <Icon type={ValueTruthy(value) ? IconType.on : IconType.off} addClass={`click config-switch ${ValueTruthy(value) ? 'text-info' : ''}`} title="click to toggle on or off" />
              {config.Name}{props.Config.Required && <>*</>} {zFairsOnlyLabel(props.Config)}
            </label>
            {config.Description && (
              <span
                title="more info"
                className="click"
                onClick={() => {
                  setShowMore(true);
                }}>
                <Icon type={IconType.info} />
              </span>
            )}
          </>
        );
        break;
      case 'html-full':
        return (
          <>
            <div className="flex-between w-100">
              <label htmlFor={config.PublicId} className="control-label">
                {config.Name}{props.Config.Required && <>*</>} {zFairsOnlyLabel(props.Config)}
                <HtmlEditorPro2
                  value={value}
                  changed={(x) => {
                    setValue(x);
                  }}
                  id={config.PublicId}
                  btnClass={'btn-xs' + ` config-html-full id-${props.Config.PublicId} ${props.Config.Required ? 'x-required' : ''}`}
                />
              </label>

              {config.Description && (
                <span
                  title="more info"
                  className="click"
                  onClick={() => {
                    setShowMore(true);
                  }}>
                  <Icon type={IconType.info} />
                </span>
              )}
            </div>
          </>
        );
        break;
      case 'html':
      case 'textarea':
        return (
          <>
            <div className="flex-between w-100">
              <label htmlFor={config.PublicId} className="control-label">
                {config.Name}{props.Config.Required && <>*</>} {zFairsOnlyLabel(props.Config)}
                <button
                  type="button"
                  className={'btn btn-secondary btn-xs small' + ` config-html id-${props.Config.PublicId} ${props.Config.Required ? 'x-required' : ''}`}
                  disabled={!enabled}
                  onClick={() => {
                    if (enabled) setShowEditor(true);
                  }}>
                  <Icon type={IconType.edit} /> Show Editor
                </button>
              </label>

              {config.Description && (
                <span
                  title="more info"
                  className="click"
                  onClick={() => {
                    setShowMore(true);
                  }}>
                  <Icon type={IconType.info} />
                </span>
              )}
            </div>
          </>
        );
        break;
      case 'custom':
        if (props.Config.Key === 'Grade.Fair') {
          return (
            <>
              <div className={'flex-between w-100' + ` config-grade id-${props.Config.PublicId} ${props.Config.Required ? 'x-required' : ''}`}>
                <label htmlFor={config.PublicId} className="control-label">
                  {config.Name}{props.Config.Required && <>*</>} {zFairsOnlyLabel(props.Config)}
                </label>

                {config.Description && (
                  <span
                    title="more info"
                    className="click"
                    onClick={() => {
                      setShowMore(true);
                    }}>
                    <Icon type={IconType.info} />
                  </span>
                )}
              </div>
              <InputGradeRange
                key={props.Config.PublicId}
                forLow={config.PublicId}
                type={'full-pipes'}
                onBlur={()=>{updateOnBlur()}}
                readOnly={!enabled}
                onValueChange={(v) => {
                  setValue(v + '');
                }}
                value={value}
                delimiter={'|'}
                clickToEdit={false}
              />
            </>
          );
        }
        return (
          <>
            <div className={'flex-between w-100' + ` config-custom id-${props.Config.PublicId} ${props.Config.Required ? 'x-required' : ''}`}>
              <label htmlFor={config.PublicId} className="control-label">
                {config.Name}{props.Config.Required && <>*</>} {zFairsOnlyLabel(props.Config)}
                <button
                  type="button"
                  className="btn btn-secondary btn-xs small"
                  disabled={!enabled}
                  onClick={() => {
                    if (enabled) {
                      let c = props.Config;
                      setSelectedItem({
                        Name: props.Config.Name,
                        Id: c.Key,
                        Type: c.Key,
                      });
                      setShowEditModal(true);
                    }
                  }}>
                  <Icon type={IconType.edit} /> Show Editor
                </button>
              </label>

              {config.Description && (
                <span
                  title="more info"
                  className="click"
                  onClick={() => {
                    setShowMore(true);
                  }}>
                  <Icon type={IconType.info} />
                </span>
              )}
            </div>
          </>
        );
        break;
      case 'file-path-img':
        return (
          <>
            <div className={'flex-between w-100' + ` config-text id-${props.Config.PublicId} ${props.Config.Required ? 'x-required' : ''}`}>
              <label htmlFor={config.PublicId} className="control-label">
                {config.Name}{props.Config.Required && <>*</>} {zFairsOnlyLabel(props.Config)}
              </label>

              {config.Description && (
                <span
                  title="more info"
                  className="click"
                  onClick={() => {
                    setShowMore(true);
                  }}>
                  <Icon type={IconType.info} />
                </span>
              )}
            </div>
            <EasyFileInput
              htmlId={config.PublicId}
              exts={'.jpg,.jpeg,.png,.gif'}
              value={value}
              onSelectFile={(f) => {
                console.log('File Changed: onSelectFile');
                fileChange(f);
              }}
              onSelect={(v) => {
                console.log('File Changed: onSelect');
                fileChange(undefined, v);
              }}
              valueOptions={config.ValueOptions}
            />
          </>
        );
        break;
      case 'color':
          return (
            <>
              <div className="flex-between w-100">
                <label htmlFor={config.PublicId} className="control-label" dangerouslySetInnerHTML={{ __html: props.Config.Name + (props.Config.Required ? "*":'') }} />
                {zFairsOnlyLabel(props.Config)}
                {config.Description && (
                  <span
                    title="more info"
                    className="click"
                    onClick={() => {
                      setShowMore(true);
                    }}>
                    <Icon type={IconType.info} />
                  </span>
                )}
              </div>
              <input
                type="color"
                id={config.PublicId}
                className={'form-control' + ` config-text id-${props.Config.PublicId} ${props.Config.Required ? 'x-required' : ''}`}
                maxLength={maxLength(props.Config.MaxLength, 250)}
                value={value ?? ''}
                onBlur={()=>{updateOnBlur()}}
                disabled={!enabled}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
              />
            </>
          );
          break;
        default:
        return (
          <>
            Unknown Input Type: {props.Config.ConfigType} ({props.Config.DataType}) {props.Config.Name} {props.Config.PublicId}
          </>
        );
        break;
    }
  };

  const GetComplexType = (config: IConfigBase) => {
    //TODO check if there is a modal to open...
    //Else
    if (config.ComplexKey && config.ComplexKey.toLowerCase().indexOf('modal:') > -1) {
      return (
        <div className={'config-group-input' + ` config-complex id-${props.Config.PublicId} ${props.Config.Required ? 'x-required' : ''}`}>
          <button
            type="button"
            onClick={() => {
              setShowConfigEditorModal(true);
            }}
            className="btn btn-secondary complex-btn"
            title={`Open ${config.Name} to manage it.`}>
            <Icon type={IconType.configure} /> {config.Name}
          </button>
        </div>
      );
    } else if (config.ComplexKey && config.ComplexKey.toLowerCase().indexOf('header') > -1) {
      return (
        <div className={'config-group-input-header' + ` config-custom id-${props.Config.PublicId} ${props.Config.Required ? 'x-required' : ''}`}>
          <h4>{config.Name}</h4>
          {config.Description && <div dangerouslySetInnerHTML={{ __html: config.Description }}></div>}
        </div>
      );
    } else if (config.ComplexKey && config.ComplexKey.toLowerCase().indexOf('inline') > -1) {
      let values = config.ComplexKey.toLowerCase().split(':');
      let close = values[0].indexOf('close') > -1 || props.minimize;
      return (
        <div className={'config-group-input-inline' + ` config-custom id-${props.Config.PublicId} ${props.Config.Required ? 'x-required' : ''}`}>
          {config.Description && <div dangerouslySetInnerHTML={{ __html: config.Description }}></div>}
          <ConfigFormLanding type={values[1]} expandable={true} startClosed={close} />
        </div>
      );
    } else if (config.ComplexKey)
      return (
        <div className={'config-group-input' + ` config-complex id-${props.Config.PublicId} ${props.Config.Required ? 'x-required' : ''}`}>
          <a href={StoreX.BuildUrlWithFair(config.ComplexKey)} className="btn btn-secondary complex-btn" target="_blank" title={`Open ${config.Name} in a new tab.`}>
            <Icon type={IconType.configure} /> {config.Name}
          </a>
        </div>
      );

    return <div className={'config-group-input' + ` config-complex id-${props.Config.PublicId} ${props.Config.Required ? 'x-required' : ''}`}>Unknow complex type... </div>;
  };
  const zFairsOnlyLabel = (config: IConfigBase) => {
    if (props.Config.UserRoleAccess?.toLowerCase() === 'zfairs') {
      return <span className="label label-warning bummper-left">zFairs Only</span>;
    }
    return <></>;
  };

  const fileChange = (file?: File, v?: any) => {
    if (props.valueFileUpdated) {
      let newValue = file?.name ?? v;// ?? value;
      console.log(newValue, props.Config.PublicId);
      console.log(file);
      props.valueFileUpdated(props.Config.PublicId, newValue, file);
      setStartValue(newValue);
      setValue(newValue);
    } else {
      console.log('valueFileUpdated not set!');
    }
  };

  return (
    <>
      {props.Config && <>
      <div id={`config:${props.Config.PublicId}`}>
      {buildSetting()}
      </div>
      </>}
      {showMore && (
        <Modal setModalOpen={setShowMore} title={`More Info`} className={``} size="l">
          <h4>{props.Config.Name}</h4>
          <div>
            <div dangerouslySetInnerHTML={{ __html: props.Config.Description }}></div>
          </div>
        </Modal>
      )}
      {showEditor && (
        <Modal
          setModalOpen={() => {
            updateOnBlur();
            setShowEditor(false);
          }}
          title={`Editor`}
          className={``}
          size="xl"
          // noCloseOnBackgroundClick
        >
          <h4>{props.Config.Name}</h4>
          <div>
            
            {props.Config.DataType === 'html' ? (
              <Editor
                height="normal"
                value={value ?? ''}
                options={'full'}
                changed={(x) => {
                  setValue(x);
                }}
                onblur={(value)=>{updateOnBlur2(value)}}
                readOnly={false}
                saveBeforeChange={true}
                close={()=>{setShowEditor(false)}}
                hideExpand={true}
              />
            ) : (
              <>
                <textarea
                  id={props.Config.PublicId}
                  className="form-control"
                  maxLength={maxLength(props.Config.MaxLength, 250)}
                  value={value ?? ''}
                  onBlur={()=>{updateOnBlur()}}
                  disabled={!enabled}
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                  rows={15}></textarea>
              </>
            )}
          </div>
        </Modal>
      )}

      {selectedItem && showEditModal && (
        <ConfigFormEditor
          type={selectedItem.Type ?? props.Config.Key ?? 'oops'}
          item={selectedItem}
          canSave={true}
          noEdit={false}
          onClose={() => {
            setShowEditModal(false);
          }}
          onSave={() => {
            setSelectedItem(undefined);
            setShowEditModal(false);
          }}
          onRemove={() => {
            setSelectedItem(undefined);
            setShowEditModal(false);
          }}
        />
      )}

      {showConfigEditorModal && (
        <Modal setModalOpen={setShowConfigEditorModal} title={props.Config.Name} className={``} size="xl">
          <ConfigFormLanding type={props.Config.ComplexKey?.substring(6) ?? 'oops'} />
        </Modal>
      )}
    </>
  );
};

export default SetupConfig;
