import * as React from "react";
import { useState, useEffect } from "react";
import StoreX, { store } from "../../redux/oldStore";
import Select from "react-select";
import { toast } from "react-toastify";
import Icon, { IconType } from "../Icon/Icon";
import ExpandableDiv, { ExpandableImg, LinkToView, IImage } from "../Tools/ExpandableDiv";
import ProjectView from "../Project/ProjectView";
import imgMissingPerson from "../../../public/Assets/Images/unknownUser.png";
import '../Showcase/projectDetailsStyles.css';
import { ICustomQuestions, IProject, IShowcaseSettings, IAward, ITag } from "./ShowcaseTypes";

interface IProjectDetails2Props{
    project:IProject;
    settings:IShowcaseSettings;
    projectQuestions:ICustomQuestions[];
    handleTagChange?:Function;
    availableProjectTags?:ITag[];
    categoryAwardsDict?:{[key: number]: IAward};
    specialAwardsDict?:{[key: number]: IAward};
    myLikes: any;
    likeProject:Function;
    likes:number;
}
export const imageExtTypeDic:Record<string,boolean> = {};
imageExtTypeDic[".jpg"] = true;
imageExtTypeDic[".jpeg"] = true;
imageExtTypeDic[".png"] = true;
imageExtTypeDic[".tiff"] = true;

export const ProjectDetails2 = (props:IProjectDetails2Props) => {
  const { project, settings, projectQuestions } = props;
  let owner = project.owners[0];
  
  let images = project.images?.filter(x=> imageExtTypeDic[x.ext] && x.fileType == "project-pic") ?? [];
  let files = project.images.filter(x=>x.ext === '.pdf' && x.fileType == "project-pic") ?? [];
  let fileEntryVideo = project.images.find(x=> x.fileType == "entry-video-upload" && settings.showVideo);
  let pitchVideo = project.images.find(x=> x.fileType == "pitch-video" && settings.showPitchVideo);
  let fileSlides = project.images.find(x=> x.fileType == "slides-upload" && settings.showSlides);
  let fileLogbook = project.images.find(x=> x.fileType == "log-book" && settings.showLogbook);
  let writtenMatterials = project.images.find(x=> x.fileType == "written-materials");
  let entryFile = project.images.find(x=> x.fileType == "entryfile");
  const [selectedImage, setSelectedImage] = useState(images[0] || {});
  const [thisTags, setThisTags] = useState<ITag[]>([]);
  const [ownerImages, setOwnerImages] = useState<IImage[]>([]);
  const [showAddTag, setShowAddTag] = useState(false);
  const [selectedTag, setSelectedTag] = useState({
    label: "Choose a Tag",
    value: "",
  });
  const [likes, setLikes] = useState<number>(props.likes);

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      // width: state.selectProps.width,
      // borderBottom: '1px dotted pink',
      maxHeight: "20vh",
      color: state.selectProps.menuColor,
    }),
    menuList: (provided, state) => ({
      ...provided,
      maxHeight: "20vh",
    }),
  };

  useEffect(() => {
      if(props.availableProjectTags){
    if (project.myTags) {
      let theseTags = props.availableProjectTags.filter((tag) =>
        project.myTags.includes(tag.Id)
      );
      setThisTags(theseTags);
    }
    }

    setOwnerImages(getTeamMemberImages());
  }, [project]);

  const mappedProjectTags = [
    { label: "Choose a Tag", value: "" },
    ...(props.availableProjectTags ?? [])
      .filter((tag) => !project.myTags?.includes(tag.Id))
      .map((tag) => {
        return {
          label: tag.Value,
          value: tag.Id,
        };
      }),
  ];

  const handleSaveThis = () => {
    if (!selectedTag?.value) {
      toast.error("need to choose a tag");
      return;
    }
    let reqBody = {
      id: project.publicId, //projectGuid
      tagId: selectedTag.value,
      remove: false,
    };
    store.server
      .postApi("../Tag/TagIt", reqBody)
      .then((res) => {
        console.log(res);
        if(props.handleTagChange) props.handleTagChange(project, selectedTag, false);
               
        setSelectedTag({ label: "Choose a Tag", value: "" });
        setShowAddTag(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleRemoveTag = (tag) => {
    let reqBody = {
      id: project.publicId,
      tagId: tag.Id,
      remove: true,
    };
    store.server
      .postApi("../Tag/TagIt", reqBody)
      .then((res) => {
        console.log(res);
        if(props.handleTagChange) props.handleTagChange(project, tag, true);
        setSelectedTag({ label: "Choose a Tag", value: "" });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getTeamNames = () =>{
    if(settings.hideShowcaseStudentName || !project.owners){
      if(project.owners.find(x => x.School)){
        return <div className="flex">
        {project.owners.filter(x => x.School).map((x, i) => {
         return (
              <div>
              <span
                className="school-name"
              >
                <Icon type={IconType.school} /> {x.School}
              </span>
              </div>
         )})}
         </div>}
         else{
          return <></>
         }
    }

    if(project.owners.length > 1){
    return <>
      Team:  <br/>
      <div className="flex">
      {project.owners.map((x,i)=>{
        return (
              <div>
              <span
                key={`project-owner-name-${i}`}
                className="project-owner-name"
              >
                <Icon type={IconType.user} /> {x.firstName} {x.lastName}
              </span>
              {x.School &&
              <div className="small">
                &nbsp;&nbsp;&nbsp;&nbsp;<Icon type={IconType.school} /> {x.School}
              </div>}
              </div>
        );
      })}
      </div>
    </>
  }else{
    return <>
      By:  <br/>
      <div className="flex">
      {project.owners.map((x,i)=>{
        return <div>
            <span
              key={`project-owner-name-${i}`}
              className="project-owner-name"
            >
              <Icon type={IconType.user} /> {x.firstName} {x.lastName}
            </span>
            {x.School &&
            <div className="small">
              &nbsp;&nbsp;&nbsp;&nbsp;<Icon type={IconType.school} /> {x.School}
           </div>}
            </div>
      })}
      </div>
    </>
  }
  }

  const getTeamMemberImages = ()=>{
    let images:IImage[] = [];
    project.owners.forEach(x=>{        
      let img:IImage = {path:'', alt: `${x.firstName} ${x.lastName}`, missing: false, type:'person'};
      
      if(x.profileKey){        
        img.path = x.porfilePath;
      } else {
        img.missing = true;
        img.path = imgMissingPerson;
      }
      images.push(img);
    });
    return images;
  };

  
  const handleLike = (e)=>{
    const value = props.myLikes[project.projectIntId];
    var like = true;
    if(value === true || value === "true")
    like = false;
    props.myLikes[project.projectIntId] = like;
    
    if(like) setLikes(likes+1);
    else setLikes(likes- 1);

    props.likeProject(project.projectIntId, like);
    e.stopPropagation();
  };
  return (
    <div
      className="showcase-project details"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >

{props.availableProjectTags && props.availableProjectTags.length > 0 && (
        <div>
          <div>
            <div className="flex-between">
            <div className="flex wrap ">
              {thisTags.map((tag, i) => {
                return (
                  <div key={`${tag.Id}-${i}`} className="nice-tag flex">
                    <Icon type={IconType.tag}/> {tag.Value}
                    <span
                      onClick={() => handleRemoveTag(tag)}
                      className="click"
                    >
                      <Icon type={IconType.trashO}/>
                    </span>
                  </div>
                );
              })}
            </div>
            <button type="button" className="btn btn-xs btn-default"
              onClick={()=>{setShowAddTag(!showAddTag);}}
            >
              <Icon type={IconType.tags} /> Tag
            </button>
            </div>

            {showAddTag && 
            <div className="box tag-it">
              <div className="form-horizontal">
                <div className="form-group">
                  <label htmlFor="pd-tag" className="control-label col-sm-5">Tag</label>
                  <div className="col-sm-4">
                      <Select
                        onChange={setSelectedTag}
                        value={selectedTag}
                        options={mappedProjectTags.map((x) => {return{label:x.label, value:x.value+''}})}
                        styles={customStyles}
                        placeholder={"choose tag"}
                        className=""
                      />
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-sm-4 col-sm-offset-5">
                    <button
                      onClick={handleSaveThis}
                      className="btn btn-secondary"
                    >
                      <Icon type={IconType.tag} /> Tag
                    </button>
                    <button
                      onClick={()=>{setShowAddTag(false)}}
                      className="btn btn-default"
                    >
                      <Icon type={IconType.close} /> Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
            }
          </div>
        </div>
      )}

      <div className="header box">

        {images?.length > 0 && <div className="primary-image">
          <ExpandableImg imageSrc={images.map(x=>{return {path:x.path, alt:x.comments};})} />
        </div>}
        <div className="title-description">
          <div className="title">
            {project.projectId && <span>{project.projectId} - </span>}
            {project.title}
          </div>
                        <ExpandableDiv>{project.projectDescription}</ExpandableDiv>
          <div>
            {getTeamNames()}
          </div>
          <div className="category-name">
            {project.category}
          </div>
        </div>
      </div>

{props.settings.showAwards && (project.categoryAwards || project.specialAwards)
 
            &&<div className="box awards">
               { props.categoryAwardsDict && <>
            {project.categoryAwards
              ?.filter(x => props.categoryAwardsDict[x])
              .map((num, i) => {
                return (
                  <div 
                    className='text-danger'
                    key={`${props.categoryAwardsDict[num].Id} - ${i}`}
                    title={props.categoryAwardsDict[num].Name}
                  >
                    <i className="fas fa-award"></i> {props.categoryAwardsDict[num].Name}                       
                  </div>
                  )
              })
            }</>}
            { props.specialAwardsDict &&<>
                {project.specialAwards
                  ?.filter(x => props.specialAwardsDict[x])
                  .map((num, i) => {
                    return (
                      <div 
                        className='text-info'
                        key={`${props.specialAwardsDict[num].Id} - ${i}`}
                        title={props.specialAwardsDict[num].Name}
                      >
                        <i className="fas fa-award"></i> {props.specialAwardsDict[num].Name}
                        
                      </div>
                      )
                  })
                }
                </>}
                </div>
          }


      {(images?.length > 0 || files?.length > 0 || fileLogbook || fileEntryVideo || fileSlides || pitchVideo) && 
      <div className="box media-grid">       

{fileEntryVideo && (
                  <div className="media-el file">
                  <LinkToView type="video" urlSource={fileEntryVideo.path} name="Entry Video" fill={true}/>
                  </div>
      )} 
      {fileLogbook && (
                  <div className="media-el file">
                  <LinkToView type="pdf" urlSource={fileLogbook.path} thumbnail={`${StoreX.BuildUrlWithFair(`../file/${fileLogbook.key}/true/500`)}`} name="Logbook" fill={true}/>
                  </div>
      )} 
      {fileSlides && (
                  <div className="media-el file">
                  <LinkToView type="pdf" urlSource={fileSlides.path} thumbnail={`${StoreX.BuildUrlWithFair(`../file/${fileSlides.key}/true/500`)}`}  name="Slides" fill={true}/>
                  </div>
      )} 
      
      {pitchVideo && (
                  <div className="media-el file">
                  <LinkToView type="video" urlSource={pitchVideo.path} name="Pitch Video" fill={true}/>
                  </div>
      )} 
        {images?.length > 0 &&  (
          <>
            {images.filter(x=>x.path).map((x,i)=>{
          return <div className="media-el" key={`pd-image-${i}`}>
              <ExpandableImg
                imageSrc={[{path:x.path, alt:x.comments}]}
              />
              {x.key}
              </div>
            })}
          </>
        )}
        {files?.length > 0 &&  (
          <>
            {files.map((x,i)=>{
          return <div className="media-el file">
              <LinkToView type="pdf" urlSource={x.path} thumbnail={`${StoreX.BuildUrlWithFair(`../file/${x.key}/true/500`)}`} />
              </div>
            })}
          </>
        )}
      </div>
}

{!settings.hideShowcaseStudentProfileImages && ownerImages?.length > 0 && <div className="box media-grid">
        {ownerImages.filter(x=>x.path).map((x,i)=>{
          return <div className="media-el" key={`o-image-${i}`}>
              <ExpandableImg
                imageSrc={[x]}
              />
              </div>
            })}
  </div>}
      
      {project.projectPlan && <div className="box">
        <h4>Plan</h4>
        <ExpandableDiv>
          {project.projectPlan}
        </ExpandableDiv>
        </div>}
      {project.projectAbstract && <div className="box">
        <h4>Abstract</h4>
        <ExpandableDiv>
          {project.projectAbstract}
        </ExpandableDiv>
        </div>}

        {projectQuestions &&
          projectQuestions.length &&
          project.projectQuestionsResponses && (
            <div className="box">
              <h4>Entry Questions</h4>
            <ExpandableDiv>
              {projectQuestions.map((x: any, i: number) => {
                const response = project.projectQuestionsResponses.find(
                  (r) => r.QuestionId === x.id
                );
                if (!response) return null;

                if (
                  response.Value &&
                  response.Value.toUpperCase().indexOf("HTTP") === 0
                ) {
                  return (
                    <div key={`pq-${i}`}>
                      <strong>{x.question}</strong>{" "}
                      <span>
                        <a href={response.Value} target="_blank">
                          {response.Value}
                        </a>
                      </span>
                    </div>
                  );
                }

                return (
                  <div key={`pq-${i}`}>
                    <strong>{x.question}</strong> <span>{response.Value}</span>
                  </div>
                );
              })}
            </ExpandableDiv>
            </div>
          )}


{ 
(project.videoUrl || project.videoUrl2 || project.files || project.EntryFile || project.WrittenMatterials || project.EntryUrl)
&& <div className="box">
  <h4>Additional Information</h4>
      <ExpandableDiv>
        {project.videoUrl && (
          <div className="video-url">
            <strong>Video</strong>&nbsp;
            <a href={project.videoUrl} target="_blank">
              {project.videoUrl}
            </a>
          </div>
        )}
        {project.videoUrl2 && (
          <div className="video-url">
            <strong>Video</strong>&nbsp;
            <a href={project.videoUrl2} target="_blank">
              {project.videoUrl2}
            </a>
          </div>
        )}
        {project.files && (
          <>
            {project.files.map((x) => {
              return (
                <div className="file" key={x.url}>
                  <LinkToView type="pdf" urlSource={x.url} name={x.name}/>
                </div>
              );
            })}
          </>
        )}
        {project.EntryFile && entryFile && (
                          <div className="file" >
                          <LinkToView type="pdf" urlSource={entryFile.path} name={`Entry File`}/>
                        </div>
        )}

        {project.WrittenMatterials && writtenMatterials &&(
                          <div className="file" >
                          <LinkToView type="pdf" urlSource={writtenMatterials.path} name={`Written Matterials`}/>
                        </div>
        )}
        
        {project.EntryUrl && (
          <div>
              <a
                href={`https://${project.EntryUrl}.NHDWebCentral.org`}
                rel="noopener"
                target="_blank"
              >
                {`${project.EntryUrl}.NHDWebCentral.org`}
              </a>
          </div>
        )}
        </ExpandableDiv>
      </div>}

      
      {settings.recordLikes && <div className="like-container">
          <span className="click"  onClick={handleLike}>
            
          {likes > 0 && <>{likes}</>}
          {props.myLikes[project.projectIntId] ? <i className="fad fa-thumbs-up text-info"></i> : <i className="fal fa-thumbs-up text-muted"></i>}
          
          </span>
        </div>}

    </div>
  );
}

