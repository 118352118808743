import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import StoreX, { store } from '../../redux/oldStore';
import { FieldTypes, IFileDetails, SavePersonFormResponse, UiFormData, UiFormField, UiFormTab, UiValuePairs } from '../../utils/CommonTypes/UserTypes';
import Icon, { IconType } from '../Icon/Icon';
import _ from 'lodash';
import Constants from '../../utils/Constants';
import FormSection from './FormSection';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { ServerResponse } from '../../utils/Server';
import { SecurityRole } from '../../utils/CommonTypes/PeoplePageTypes';
import { updateCall } from 'typescript';
import OrderHistory from '../Store/OrderHistory';
import Nominations from '../Nominations/Nominations';
import ParticipantFileManager from '../ParticipantFileManagement/ParticipantFileManager';
import UserProjectDisplay from '../UserProject/UserProjectDisplay';
import Modal from '../Modal/Modal';
import notListedModule from './NotListedModule';
import NotListedModule from './NotListedModule';
import GenericFormsLanding from '../GenericForms/GenericFormsLanding';
import UserMiscellaneous from './UserMiscellaneous';
import UserPartyManager from './UserPartyManager';
import NhdMedicalForm from '../User/NhdMedicalForm';

interface FormFromApiDataProps {
  getInfoRoute: string;
  saveRoute: string;
  idCalledInApi: string;
  isNew: boolean;
  isFlex?: boolean;
  FileChanged?: (targetId: string, key: string, file?: File) => void;
}

export const isFieldRegexSatisfied = (field: UiFormField, formValues: Record<string, string[]>) => {
  if (!field.ValidationRegularExpression) return true;

  let value = field.Value ? field.Value[0] : null;
  if (formValues[field.Key] && formValues[field.Key][0]) value = formValues[field.Key][0];

  if (!value) return true;

  var regex = new RegExp(field.ValidationRegularExpression, `g`);
  if (regex && value.match(regex)) return true;
  else return false;
};

export const isFieldSatisfied = (field: UiFormField, formValues: Record<string, string[]>) => {
  if (!field.Required || !field.Show) return true;
  if (field.Type === FieldTypes.Alert || field.Type === FieldTypes.AlertInfo || field.Type === FieldTypes.Header) return true;

  if (field.Type === FieldTypes.ReCaptcha) {
    let w: any = window;
    let value = w.x_reCaptcha;
    if (value) {
      formValues[field.Key] = [value, 'Added By Extra...'];
    }
    console.log('reCaptcah Value', value);
  }

  if (field.Type === FieldTypes.Checkbox) {
    //must be true...
    if (formValues[field.Key]) {
      //new value set, is this value valid?
      if (formValues[field.Key]?.find((x) => x === 'true' || x === 'True')) return true;
      return false;
    }
    if ((field.Value && field.Value[0] === 'true') || field.Value[0] === 'True') return true;
    return false;
  }
  if (field.Type === FieldTypes.ProfileImage || field.Type === FieldTypes.ProjectFile) {
    if (field.Files && field.Files.length > 0) return true;
  }
  if (formValues[field.Key]) {
    //new value set, is this value valid?
    if (formValues[field.Key]?.find((x) => x)) return true;
    else return false;
  }
  if (field.Value && field.Value[0]) return true;
  return false;
};

export const isConfirmSatisfied = (field: UiFormField, formValues: Record<string, string[]>) => {
  if (!field.Confirm) return true;

  if (formValues[field.Key] || formValues[field.Key + 'confirm']) {
    try {
      return formValues[field.Key + 'confirm'] && formValues[field.Key] && formValues[field.Key][0] === formValues[field.Key + 'confirm'][0];
    } catch {
      return false;
    }
  }
  return true;
};


const FormFromApiData = (props: FormFromApiDataProps) => {
  const {userId, targetId, roleName, entryType, recordId} = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [originalFormData, setOriginalFormData] = useState<UiFormData>();
  const [tabs, setTabs] = useState<UiFormTab[]>([]);
  const [formValues, setFormValues] = useState<Record<string, string[]>>({});
  const [groupIds, setGroupIds] = useState<Record<string, UiFormField[]>>({});
  const [tabIndex, setTabIndex] = useState(0);
  const [formErrors, setFormErrors] = useState<Record<string, UiFormField>>({});
  const [subFormKeys, setSubFormKeys] = useState<string[]>();

  const [showSpecial, setShowSpecial] = useState<boolean>();
  const [specialField, setSpecialField] = useState<UiFormField>();
  const [specialSchoolFields, setSpecialSchoolFields] = useState<UiValuePairs[]>();
  const [startingTabSet, setStartingTabSet] = useState<boolean>(false);

  useEffect(() => {
    if (originalFormData?.Tabs) {
      let tabs = originalFormData.Tabs.filter((x) => x.Active && x.Show && (x.SpecialType || x.Sections?.filter((s) => s.Fields && s.Fields.length > 0).length > 0));
      setTabs(tabs);
    }
  }, [originalFormData?.Tabs]);

  useEffect(() => {
    if (tabs && tabs.length > 1 && !startingTabSet) {
      let value = StoreX.GetQueryStringValue('tab');
      if (value) {
        for (let i = 0; i < tabs.length; i++) {
          let tab = tabs[i];
          if (tab.Id?.toLowerCase() === value.toLowerCase()) {
            setStartingTabSet(true);
            setTabIndex(i);
            break;
          }
        }
      }
    }
  }, [tabs]);

  useEffect(() => {
    let password = StoreX.GetQueryStringValue('pass');
    let team = StoreX.GetQueryStringValue('team');
    store.server
      .postApi<ServerResponse<any>>(props.getInfoRoute, {
        [props.idCalledInApi]: targetId ?? recordId ?? Constants.EMPTY_GUID,
        roleName: roleName,
        personId: userId,
        entryType: entryType,
        queryTeam: team,
        queryPassword: password,
        isNew: props.isNew,
      })
      .then((res) => {
        if (!res.Success) {
          toast.error(`Error: ${res.Message}`, { autoClose: false });
          return;
        }
        if (res.GoTo) {
          window.location.href = StoreX.BuildUrl(res.GoTo);
          return;
        }
        if (!res.Value) {
          toast.error(`Server response is empty. ${res.Message}`, {
            autoClose: false,
          });
          return;
        }
        if (res.Value.redirectTo) {
          window.location.href = res.Value.redirectTo;
          return;
        }
        if (res.Value?.targetId !== targetId) {
          // navigate(`/app/user/profile/${res.Value?.targetId}/${roleName}`)
        }
        let allValues: UiFormData = res.Value;

        if (allValues.PageTitle) {
          let doc: any = window.document;
          doc.titleSet = true;
          doc.title = allValues.PageTitle;
        }

        let formKeys: string[] = [];
        if (allValues.SubForm) {
          allValues.SubForm.Tabs.forEach((t) => {
            t.Order += 10000;
            t.targetId = allValues.SubForm.targetId;
            allValues.Tabs.push(t);
            if (t.Sections)
              t.Sections.forEach((s) => {
                if (s.Fields)
                  s.Fields.forEach((f) => {
                    formKeys.push(f.Key);
                  });
              });
          });
        }
        setSubFormKeys(formKeys);

        let sortedTabs = {
          ...allValues,
          Tabs: _.sortBy(allValues?.Tabs, 'Order'),
        };
        let hasFields = false;
        let show = true;
        let sortedSections = {
          ...sortedTabs,
          Tabs: sortedTabs.Tabs?.map((tab: UiFormTab, i: number) => {
            let orderedSections = _.sortBy(tab.Sections, 'Order');
            let orderedFields = orderedSections.map((sect) => {
              let orderedFields = _.sortBy(sect?.Fields, 'Order');
              sect.Fields = orderedFields;
              return sect;
            });
            if (orderedFields && orderedFields.length > 0) hasFields = true;
            if (!tab.targetId) tab.targetId = allValues.targetId;
            tab.Sections = orderedFields;
            tab.Show = tab.Active && show;
            if (tab.Active && show && (allValues.NewRecord || allValues.FirstTimeLogin)) show = false;
            return tab;
          }),
        };
        setOriginalFormData(sortedSections);
        getAndSetGroupIds(res.Value);

        if (!hasFields && allValues.NewRecord) {
          //no form so click save & continue
          redirectAfterSuccessfulSave(sortedSections.targetId, allValues.role, allValues);
        }
      });
  }, [targetId, props.getInfoRoute]);

  const getAndSetGroupIds = (form: UiFormData) => {
    let groupIdGroups: Record<string, UiFormField[]> = {};
    form?.Tabs.forEach((tab) => {
      if (tab.Active) {
        tab.Sections.forEach((sect) => {
          if (sect.Active) {
            sect?.Fields.forEach((field) => {
              if (field.GroupId) {
                if (groupIdGroups[field.GroupId]) {
                  groupIdGroups = {
                    ...groupIdGroups,
                    [field.GroupId]: [...groupIdGroups[field.GroupId], field],
                  };
                } else {
                  groupIdGroups = {
                    ...groupIdGroups,
                    [field.GroupId]: [field],
                  };
                }
              }
            });
          }
        });
      }
    });
    setGroupIds(groupIdGroups);
  };

  const handleSave = async (e: any | undefined) => {
    e?.preventDefault();
    window.scroll(0, 0);

    const formValues = getFormValuesWithDefaults();
    if (notAllFormFieldsAreFilledProperly(formValues) && !originalFormData?.ByPassRequired) {
      console.log('Not all fields are properly filled out.');
      return;
    }
    //TODO: what api am I sending this to?

    let password = StoreX.GetQueryStringValue('pass');
    let team = StoreX.GetQueryStringValue('team');

    let data: any = {
      values: formValues,
      targetId: originalFormData?.targetId,
      entryType: entryType,
      queryPassword: password,
      roleName: roleName,
      queryTeam: team,
      flowId: originalFormData?.flowId,
    };

    if (props.saveRoute === '../Project/SaveForm') {
      delete data.targetId;
      data.projectId = targetId;
    }
    if (userId) {
      data.personId = userId;
    }

    if (originalFormData?.SubForm) {
      console.log('SubForm', subFormKeys);
      data.personId = originalFormData.targetId;
      data.projectId = originalFormData.SubForm.targetId;
      data.subFormKeys = subFormKeys;
    } else {
      //console.log('Not SubForm', subFormKeys);
    }
    try {
      toast.dismiss();
      var result = await store.server.postApi<ServerResponse<SavePersonFormResponse>>(props.saveRoute, { jsonData: JSON.stringify(data) });
      if (result.GoTo) {
        window.location.href = StoreX.BuildUrl(result.GoTo);
        return;
      }
      if (!result.Success || result.Value.errors?.length > 0) {
        let errors: string[] = result.Value.errors ?? [];
        var errorDisplayString = errors?.length ? errors.join('\n') : 'Unable to save your data.';
        toast.error(errorDisplayString, { autoClose: false });
      } else {
        toast.success('Saved!');

        let newData = { ...originalFormData };

        originalFormData?.Tabs.filter(filterTabs)[tabIndex]?.Sections.forEach((sect) => {
          sect.Fields.forEach((field) => {
            let values = formValues[field.Key];
            if (values) {
              field.Value = values;
            }
          });
        });

        setFormValues({});
        setOriginalFormData(newData);
        if (result.Value.redirectTo) {
          //server is sending me somewhere
          window.location.href = result.Value.redirectTo;
          return;
        } else if (newData) redirectAfterSuccessfulSave(result.Value.targetId, result.Value.role, newData);
        //redirect to a registration page based on target id being sent back
      }
    } catch (err) {
      toast.error('Oops, looks like there was an error when you tried to save.\n');
      console.log(err);
    }
  };

  const redirectAfterSuccessfulSave = (id: string, role: SecurityRole, data: UiFormData) => {
    //profile i'm loading is different redirect
    if (data.VerifyEmail) {
      navigate(`${data.NextRecordPath}${id}/${location.search}`);
      return;
    }
    if (window.location.pathname.startsWith(`/app/user/profile/`)) { 
      if (id !== targetId) {
        navigate(`/app/user/profile/${id}/${role}/${location.search}`);
        return;
      }
    }
    if (window.location.pathname.startsWith(`/app/user/new/`)) {
      if (id !== targetId) {
        navigate(`/app/user/new/${roleName}/${id}/${location.search}`);
        return;
      }
    }
    //flex data forms go to url with record id in it.
    if (window.location.pathname.startsWith(`/app/flex`)) {
      if (data.targetId !== recordId) {
        navigate(`/app/flex/${data.flowId}/${data.targetId}${location.search}`);
      }
    }
    //project i'm loading is different redirect
    if (window.location.pathname.startsWith(`/app/project/`)) {
      if (id !== targetId) {
        navigate(`/app/project/${userId}/${id}/${location.search}`);
        return;
      }
    }
    //move to next tab...
    //Don't get rid of tabs...
    // let tabs = data?.Tabs.filter(
    //   (tab) =>
    //     tab.Active && //tab.Show &&
    //     (tab.SpecialType ||
    //       tab.Sections?.filter((s) => s.Fields && s.Fields.length > 0).length >
    //         0)
    // ) ?? [];

    if (data) {
      const moreTabs = tabIndex < (data.Tabs.filter(filterTabs).length ?? 0) - 1;

      if ((data.NewRecord || data.FirstTimeLogin) && moreTabs) {
        if (data) {
          let tabsShowing = 0;
          let tabs = [...data.Tabs];
          for (let i = 0; i < tabs.length; i++) {
            let tab = tabs[i];
            if (tab.Active && (tab.SpecialType || tab.Sections?.filter((s) => s.Fields && s.Fields.length > 0).length > 0)) {
              tabsShowing++;
              if (!tab.Show) {
                tab.Show = true;
                break;
              }
            }
          }

          setTabIndex(Math.min(tabsShowing, tabIndex + 1));
          setOriginalFormData({ ...data, Tabs: [...tabs] });
        }
        return;
      }
    }
    if ((data?.NewRecord || data?.FirstTimeLogin) && data?.NextRecordPath) {
      navigate(`${data.NextRecordPath}/${location.search}`);
    }
  };

  const filterTabs = (tab:UiFormTab) :boolean =>{
    return tab.Active && (tab.SpecialType || tab.Sections?.filter((s) => s.Fields && s.Fields.length > 0).length > 0) ? true : false;
  }

  const getFormValuesWithDefaults = () => {
    let formValuesCopy = { ...formValues };
    originalFormData?.Tabs.filter(filterTabs)[tabIndex]?.Sections?.forEach((section) => {
      section.Fields.forEach((field) => {
        if (!!field.DefaultValue && !formValues[field.Key]?.find((x) => x) && !field.Value?.find((x) => x)) {
          formValuesCopy[field.Key] = [field.DefaultValue];
        }
      });
    });
    return formValuesCopy;
  };

  const notAllFormFieldsAreFilledProperly = (formValues: Record<string, string[]>) => {
    let requiredMissing = false;
    let missingFields: string[] = [];
    let invalid = false;
    let invalidFields: string[] = [];
    const formErrors: Record<string, UiFormField> = {};
    originalFormData?.Tabs.filter(filterTabs)[tabIndex]?.Sections.forEach((sect) => {
      sect.Fields.forEach((field) => {
        if (!isFieldSatisfied(field, formValues)) {
          formErrors[field.Key] = field;
          requiredMissing = true;
          missingFields.push(field.Label);
        }
        if (!isFieldRegexSatisfied(field, formValues)) {
          formErrors[field.Key] = field;
          invalid = true;
          invalidFields.push(field.Label);
        }
      });
    });
    if (originalFormData)
      //get data on other tabs that have been shown...
      for (let i = 0; i < originalFormData?.Tabs.length; i++) {
        if (i === tabIndex) break;
        let tab = originalFormData.Tabs[i];
        if (!tab.Show) break;
        tab.Sections.forEach((sect) => {
          sect.Fields.forEach((field) => {
            if (!isFieldSatisfied(field, formValues)) {
              formErrors[field.Key] = field;
              requiredMissing = true;
              missingFields.push(field.Label);
            }
            if (!isFieldRegexSatisfied(field, formValues)) {
              formErrors[field.Key] = field;
              invalid = true;
              invalidFields.push(field.Label);
            }
          });
        });
      }

    let error = false;

    originalFormData?.Tabs.filter(filterTabs)[tabIndex]?.Sections.forEach((sect) => {
      sect.Fields.forEach((field) => {
        if (!field.Confirm) {
        } else if (!isConfirmSatisfied(field, formValues)) {
          formErrors[field.Key] = field;
          formErrors[field.Key + 'confirm'] = field;
          error = true;
          toast.warning(`Please confirm your ${field.Label}.`);
        }
      });
    });

    originalFormData?.Tabs.filter(filterTabs)[tabIndex]?.Sections.forEach((sect) => {
      sect.Fields.forEach((field) => {
        if (!field.Confirm) {
        } else if (field.Type === FieldTypes.Password && formValues[field.Key]) {
          let errors: string[] = [];
          let value = formValues[field.Key][0];
          if (field.RequireMinLength > value.length) errors.push(`${field.Label} must be at least ${field.RequireMinLength} characters long.`);
          if (field.RequireAltCase && (!value.match(/[A-Z]/g) || !value.match(/[a-z]/g))) errors.push(`${field.Label} must have both an uppercase and lowercase letter.`);
          if (field.RequireNumber && (!value.match(/[A-Za-z]/g) || !value.match(/[0-9]/g))) errors.push(`${field.Label} must have at least one number and one letter.`);
          if (field.RequireSpecialChar && field.SpecialChars && !field.SpecialChars.find((x) => value.indexOf(x) > -1)) errors.push(`${field.Label} must have a special character.`);

          if (errors.length > 0) {
            formErrors[field.Key] = field;
            errors.forEach((x) => toast.warning(x));
            error = true;
          }
        }
      });
    });

    setFormErrors(formErrors);

    if (requiredMissing) {
      toast.warning(
        <>
          Oops, Looks like some fields are missing.
          <ul>
            {missingFields.map((x, i) => {
              return <li key={`missing-field-${i}`} dangerouslySetInnerHTML={{ __html: x }}></li>;
            })}
          </ul>
        </>
      );
    }
    if (invalid) {
      toast.warning(
        <>
          Oops, Some fields contain invalid data.
          <ul>
            {invalidFields.map((x, i) => {
              return <li key={`missing-field-${i}`} dangerouslySetInnerHTML={{ __html: x }}></li>;
            })}
          </ul>
        </>
      );
    }

    return requiredMissing || error || invalid;
  };

  const handleFieldChange = (key: string, value: string[]) => {
    if (value[0] === '<null value>') value = [];
    let updatedFormValues = { ...formValues, [key]: value };
    setFormValues(updatedFormValues);
  };
  const projectFileChanged = (key: string, files: IFileDetails[]) => {
    let data = originalFormData;
    if (!data || !data.Tabs) return;
    let field: UiFormField;
    let found = false;
    for (let t = 0; t < data.Tabs.length; t++) {
      let tab = data.Tabs[t];
      if (!tab || !tab.Sections) continue;
      for (let s = 0; s < tab.Sections.length; s++) {
        let section = tab.Sections[s];
        if (!section || !section.Fields) continue;
        for (let i = 0; i < section.Fields.length; i++) {
          let field = section.Fields[i];
          if (field && field.Key === key) {
            found = true;
            field.Files = files;
            break;
          }
        }
        if (found) {
          break;
        }
      }
      if (found) {
        break;
      }
    }
    setOriginalFormData(data);
  };

  const fieldNotListedUpdate = (field: UiFormField, newValue: string) => {
    if (originalFormData) {
      let data = { ...originalFormData };
      data?.Tabs?.forEach((t) => {
        t.Sections?.forEach((s) => {
          s.Fields?.forEach((f) => {
            if (f.Key === field.Key) {
              f.ValueOptions = field.ValueOptions;
              f.Value = [newValue];
            }
          });
        });
      });
      setOriginalFormData(data);
    }
    setShowSpecial(false);
    handleFieldChange(field.Key, [newValue]);
  };
  const handleSelectChangeWithCall = async (field: UiFormField, key: string, value: string[]) => {
    if (field.NotListSpecial && value[0] === '<NOT LISTED>') {
      setShowSpecial(true);
      setSpecialField(field);
      if (field.NotListedSchoolsDataKey) {
        originalFormData?.Tabs?.forEach((t) => {
          t.Sections?.forEach((s) => {
            s.Fields?.forEach((f) => {
              if (f.Key === field.NotListedSchoolsDataKey && f.ValueOptions) {
                setSpecialSchoolFields(f.ValueOptions);
              }
            });
          });
        });
      } else {
        setSpecialSchoolFields([]);
      }
      return;
    }
    handleFieldChange(key, value);
    if (originalFormData && field.OnChangeCallUpdate) {
      let onChangeBrokenOut = Object.entries(field.OnChangeCallUpdate);
      let copyOfOriginalForm = { ...originalFormData };

      for(let i=0; i<onChangeBrokenOut.length; i++){
        let keyVal = onChangeBrokenOut[i];
        let url = field.OnChangeCallUpdateQueryString ? `${keyVal[0]}/${value}${field.OnChangeCallUpdateQueryString}` : `${keyVal[0]}/${value}`;
        let updatedCall: any = await store.server.getApi(url);

        if (updatedCall && updatedCall.Value && updatedCall.Value.More) {
          updatedCall.Value.More.forEach((x) => {
            copyOfOriginalForm.Tabs.forEach((tab) => tab.Sections.forEach((section) => section.Fields.forEach((f) => {
              if (f.Key === x.Key) {
                f.Show = x.Show;
                if (f.Type === FieldTypes.Alert || f.Type === FieldTypes.AlertInfo) {
                  f.Value[0] = x.Values[0];
                } else {
                  f.ValueOptions = x.Values;
                }
              }
            })
            )
            );
          });
        } else {
          let keys = keyVal[1].split('|');
          copyOfOriginalForm.Tabs.forEach((tab) => tab.Sections.forEach((section) => section.Fields.forEach((f) => {
            let key = keys.find((k) => k == f.Key);
            if (key) f.ValueOptions = updatedCall?.Value;
          })
          )
          );
        }
        console.log('Call done');
      }

      console.log('About to update data');
      if (originalFormData && field.AdjustsTabs) {
        RunAdjustTabs(copyOfOriginalForm, field, value[0]);
      } else {
        setOriginalFormData(copyOfOriginalForm);
      }


      if (!onChangeBrokenOut || onChangeBrokenOut.length === 0) {
        if (field.AdjustsTabs) {
          RunAdjustTabs(copyOfOriginalForm, field, value[0]);
        }
      }
    } else if (originalFormData && field.AdjustsTabs) {
      RunAdjustTabs(originalFormData, field, value[0]);
    }
  };

  const RunAdjustTabs = (form: UiFormData, field: UiFormField, value: string) => {
    store.server.postApi<ServerResponse<Record<string, boolean>>>(`../Info/TabAdjusts${field.AdjustsTabsQueryString ?? ''}`, { field: field.Key, value: value }).then((x) => {
      if (x.Success && x.Value) {
        let newTabs = [...form.Tabs];
        newTabs.forEach((t) => {
          if (x.Value[t.Id ?? 'xxx'] !== undefined) {
            t.Active = x.Value[t.Id];
            if (!originalFormData?.NewRecord) t.Show = t.Active;
          }
        });

        setOriginalFormData({ ...form, Tabs: newTabs });
      }
    });
  };

  const ContinueButton = (tab:UiFormTab) => {
    let content:any;
    if(originalFormData?.NewRecord || originalFormData?.FirstTimeLogin){
      if(tab && tab.ButtonTextNew){
        content = <><Icon type={tab.ButtonIcon ?? originalFormData?.ButtonIcon ?? IconType.save} /> {tab.ButtonTextNew}</>
      } else if (originalFormData?.ButtonTextNew){
          content = <><Icon type={tab?.ButtonIcon ?? originalFormData?.ButtonIcon ?? IconType.save} /> {originalFormData.ButtonTextNew}</>
      } else {
        content = <><Icon type={tab?.ButtonIcon ?? originalFormData?.ButtonIcon ?? IconType.save} /> Save & Continue</>
      }
    } else {
      if(tab && tab.ButtonText){
        content = <><Icon type={tab.ButtonIcon ?? originalFormData?.ButtonIcon ?? IconType.save} /> {tab.ButtonText}</>
      } else if (originalFormData?.ButtonText){
          content = <><Icon type={tab?.ButtonIcon ?? originalFormData?.ButtonIcon ?? IconType.save} /> {originalFormData.ButtonText}</>
      } else {
        content = <><Icon type={tab?.ButtonIcon ?? originalFormData?.ButtonIcon ?? IconType.save} /> Save </>
      }

    }
    return (
      <>
        {originalFormData?.CanSave && (
          <button className="btn btn-secondary save-and-continue" onClick={handleSave}>
            {content}
          </button>
        )}
      </>
    );
  };

  const mapFormDataSections = (tab: UiFormTab) =>
    tab?.Sections?.map((x, i) => {
      if (!x.Active || x.Fields.length === 0) return <React.Fragment key={`${x.Label}-${i}`}></React.Fragment>;
      return (
        <FormSection
          key={`x-${x.Label}-${i}`}
          section={x}
          groupIds={groupIds}
          formValues={formValues}
          handleFieldChange={handleFieldChange}
          handleSelectChangeWithCall={handleSelectChangeWithCall}
          idCalledInApi={props.idCalledInApi}
          targetId={tab.targetId} //{targetId}
          formErrors={formErrors}
          canSave={originalFormData?.CanSave ?? false}
          projectFileChanged={projectFileChanged}
          handleFileChange={(key: string, file?: File) => {
            if (props.FileChanged && file) {
              props.FileChanged(tab.targetId, key, file);
              let d = { ...originalFormData };
              let found = false;
              d.Tabs?.forEach((t) => {
                t.Sections?.forEach((s) => {
                  s.Fields?.forEach((f) => {
                    if (f.Key === key) {
                      found = true;
                      f.Value = [file.name];
                    }
                  });
                });
              });
              if (found && d) {
                setOriginalFormData(d);
              }
            }
          }}
        />
      );
    });

  const buildTabs = (tbs: UiFormTab[]) => {
    if (tbs.length > 1) {
      return (
        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
          <TabList>
            {tbs.map((tab, i) => {
              return <Tab key={tab.Label + i + 'tab'}>{tab.Label}</Tab>;
            })}
          </TabList>
          {tbs.map((tab, i) => {
            return (
              <TabPanel key={tab.Label + i + 'panel'}>
                {tab.HtmlBody && <div dangerouslySetInnerHTML={{ __html: tab.HtmlBody }}></div>}

                {tab?.SpecialType === 'Orders' && (
                  <>
                    <OrderHistory userId={tab.DataPoint} showWhenEmpty={true} />
                  </>
                )}
                {tab?.SpecialType === 'SpecialAwardNominations' && (
                  <>
                    <Nominations projectId={tab.DataPoint} />
                    {originalFormData.FirstTimeLogin && <>{ContinueButton(tab)}</>}
                  </>
                )}
                {tab?.SpecialType === 'Packet' && (
                  <>
                    <div className="">
                      <GenericFormsLanding ownerId={tab.DataPoint} packetId={tab.DataPoint2} />
                    </div>
                    {(originalFormData.FirstTimeLogin || originalFormData.NewRecord) && <>{ContinueButton(tab)}</>}
                  </>
                )}
                {tab?.SpecialType === 'Paperwork' && (
                  <>
                    <div className="">
                      <ParticipantFileManager projectId={tab.DataPoint} isNew={originalFormData.NewRecord} />
                    </div>
                    {(originalFormData.FirstTimeLogin || originalFormData.NewRecord) && <>{ContinueButton(tab)}</>}
                  </>
                )}
                {tab?.SpecialType === 'Entries' && (
                  <>
                    <div className="">
                      <UserProjectDisplay AlwaysShow={true} UserId={originalFormData.targetId} />
                    </div>
                  </>
                )}
                {tab?.SpecialType === 'Miscellaneous' && (
                  <>
                    <div className="">
                      <UserMiscellaneous UserId={tab?.DataPoint ?? ''} CanSave={originalFormData.CanSave} />
                    </div>
                    {(originalFormData.FirstTimeLogin || originalFormData.NewRecord) && <>{ContinueButton(tab)}</>}
                  </>
                )}
                {tab?.SpecialType === 'Party' && (
                  <>
                    <div className="">
                      <UserPartyManager isNew={originalFormData.NewRecord} firstTimeLogin={originalFormData.FirstTimeLogin} canSave={originalFormData.CanSave} userId={originalFormData.targetId} />
                    </div>
                    {(originalFormData.FirstTimeLogin || originalFormData.NewRecord) && <>{ContinueButton(tab)}</>}
                  </>
                )}
                {tab?.SpecialType === 'nhd-medical' && tab.DataPoint && (
                  <>
                    <NhdMedicalForm
                      isFristTimeLogin={originalFormData?.FirstTimeLogin ?? false}
                      personId={tab?.DataPoint}
                      recordExists={tab.DataPoint2 ? true : false}
                      isNewUser={originalFormData?.NewRecord ? true : false}
                      canSave={originalFormData?.CanSave ?? true}
                      onContinue={() => {
                        handleSave(undefined);
                      }}
                    />
                  </>
                )}
                {!tab?.SpecialType && (
                  <>
                    <div className="form-horizontal">
                      <div className="form-area">{mapFormDataSections(tab)}</div>
                    </div>

                    <div className="col-sm-12 ">
                      <div className="col-sm-8 col-sm-offset-4 offset-line-up">
                        {originalFormData?.BackTo && (
                          <a href={`${StoreX.BuildUrl(originalFormData.BackTo.Path)}`} className={`${originalFormData.BackTo.ClassName}`}>
                            <Icon type={IconType.back} />
                            <span
                              dangerouslySetInnerHTML={{
                                __html: originalFormData.BackTo.Label,
                              }}></span>
                          </a>
                        )}
                        {ContinueButton(tab)}
                      </div>
                    </div>
                  </>
                )}
              </TabPanel>
            );
          })}
        </Tabs>
      );
    } else {
      if (originalFormData) {
        return (
          <>
            {tbs[0]?.HtmlBody && (
              <div
                dangerouslySetInnerHTML={{
                  __html: tbs[0].HtmlBody,
                }}></div>
            )}
            <div className="form-horizontal">
              <div className="form-area">{mapFormDataSections(tbs[0])}</div>
            </div>

            <div className="col-sm-12 ">
              <div className="col-sm-8 col-sm-offset-4 offset-line-up">
                {originalFormData?.BackTo && (
                  <a href={`${StoreX.BuildUrl(originalFormData.BackTo.Path)}`} className={`${originalFormData.BackTo.ClassName}`}>
                    <Icon type={IconType.back} />
                    <span
                      dangerouslySetInnerHTML={{
                        __html: originalFormData.BackTo.Label,
                      }}></span>
                  </a>
                )}
                {originalFormData?.CanSave && (
                  <>{ContinueButton(tbs[0])}</>
                )}
              </div>
            </div>
          </>
        );
      }
      return <></>;
    }
  };

  return (
    <>
      {tabs && <div className="bumper-l">{buildTabs(tabs)}</div>}
      {showSpecial && specialField && (
        <>
          <Modal setModalOpen={setShowSpecial} title="Add Not Listed" className="not-listed-special-sizer">
            <NotListedModule field={specialField} type={specialField.NotListSpecial} schools={specialSchoolFields} callBack={fieldNotListedUpdate} />
          </Modal>
        </>
      )}
    </>
  );
};

export default FormFromApiData;
