import { uploadedFile, fileType, button, fileTypeRequirementOverride, project, fileStatus,paperworkProjectsWrapper, IFormResponseSummary } from "./paperworkTypes";


export function firstOrDefault<T>(array: T[], filterFunction: (param: T) => any) {
    if (array === null || array === undefined) {
        return null;
    }
    var filtered = array.filter(x => filterFunction(x));
    return (filtered.length === 0) ? null : filtered[0];
}

export const buildButtons = (uploadedFiles: uploadedFile[], fileTypes: fileType[]
    , overrideFileTypes: fileTypeRequirementOverride[], shouldShowNonRequired: boolean
    , data:paperworkProjectsWrapper|undefined
    , project:project|undefined
    , forms:IFormResponseSummary[]|undefined
    , requiredfiles:number[]|undefined) => {
    let files = [...fileTypes];
    
    let buttons: button[] = uploadedFiles.map(uf => {
        let ft = getFileType(uf.fileType, files);
        return {
            status: uf.fileStatus,
            fileType: uf.fileType,
            fileName: ft?.name ?? uf.fileName,
            projectId: uf.projectId,
            fileId: uf.id+'',
            required: isRequiredFile(uf.id, files, overrideFileTypes, requiredfiles ?? []),
            internalFileName: uf.fileName,
            comments: uf.notes,
            blankFilePath: ft?.blankFilePath,
            ISEFFileID: ft?.ISEFFileID+'',   
            Packet:undefined,
            PacketId: undefined,
            resultsId: undefined,
            fileAbbriviation: ft?.Abbreviation,                 
        }
    });
    const overridden = getOverrideFileTypes(files, overrideFileTypes, requiredfiles ?? []);
    //If shouldShowNonRequired is set to true, buttons will be generated for all file types that
    //have don't have a matching uploaded file.  Otherwise, only buttons for required files will be generated.
    const requiredFileTypes = shouldShowNonRequired ? overridden : getRequiredFileTypes(overridden);
    requiredFileTypes.forEach(ft => {
        if (!buttons.some(b =>b.fileType === ft.id)) {
            buttons.push({
                status: ft.required ? "Required" : "",
                fileType: ft.id,
                fileName: ft.name,
                required: ft.required,
                internalFileName: "",
                blankFilePath: ft?.blankFilePath,
                ISEFFileID: ft?.ISEFFileID+'',
                fileAbbriviation: ft.Abbreviation,
            });
        }
    });

    buttons = buttons
        .sort((left, right) => {
            //order by required.  If required, it should come first.
            if (left.required > right.required) {
                return -1;
            }
            if (left.required < right.required) {
                return 1;
            }
            //Then order by file name ascending (a file name that starts with a should come before one that starts with b)
            if (left.fileName < right.fileName) {
                return -1;
            }
            if (left.fileName > right.fileName) {
                return 1;
            }
            return 0;
        });

    if(data && data.Value.packets && forms)    {
        data.Value.packets.forEach(p=>{
            let status = forms.find(x=>x.PacketId == p.Id)?.Status
            if(status) console.log('packet Status',status);
            buttons.unshift({
                Packet:p.PublicId,
                PacketId: p.PublicId,
                status: status as fileStatus,
                fileType: 0,
                fileName: p.Name ?? 'Uknown',
                fileId: '---',
                required: true,
                internalFileName: '',
                comments: '',  
            });

            if(project && project.FormInformation){

            p.Forms?.forEach(f=>{
                let fx = project.FormInformation[f.Id];

                if(fx && 
                    (fx.IsRequired
                        || fx.IsSubmitted
                        || fx.FileKey
                    )                
                ){
                    let status = fx.Status;
                    if(!status && fx.FileKey) status = 'Pending';
                    else if(!status && fx.IsRequired) status = 'Required';

                    buttons.unshift({
                        Packet:p.PublicId,
                        resultsId: fx.ResultId,
                        PacketId: p.Id,
                        status: status as fileStatus, //fx.Status || fx.IsRequired ? "Required" : fx.FileKey ? 'Pending' : '',
                        fileType: f.Id,
                        fileName: f.Name,
                        fileAbbriviation: f.Abbreviation,
                        fileId: fx.FormPublicId,
                        required: fx.IsRequired ?? false,
                        internalFileName: 'internal file name...',
                        comments: '',  
                    });
                }
            });
            }
        });

    }

    return buttons;
}

export const getFileTypeName = (uploadedFileType: uploadedFile, fileTypes: fileType[]) => {
    // var matchingFileTypes = fileTypes.filter(ft => { return ft.id === uploadedFile.fileType });
    // if (matchingFileTypes.length > 1) {
    //     throw new Error("More than one matching file type found.");
    // }
    // return matchingFileTypes.length < 1 ? uploadedFile.fileName : matchingFileTypes[0].name;
    const fileType = getFileType(uploadedFileType.fileType, fileTypes);
    return fileType?.name ?? uploadedFileType.fileName
}

export const getFileType = (uploadedFileType: number, fileTypes: fileType[]) => {
    var matchingFileTypes = fileTypes.filter(ft => { return ft.id === uploadedFileType });
    if (matchingFileTypes.length > 1) {
        throw new Error("More than one matching file type found.");
    }
    return matchingFileTypes.length < 1 ? undefined : matchingFileTypes[0];
}

const getRequiredFileTypes = (fileTypes: fileType[]) => {
    return fileTypes.filter(ft => { return ft.required });
}

export const isRequiredFile = (fileTypeId: number, fileTypes: fileType[], overrideFileTypes: fileTypeRequirementOverride[], requiredFiles:number[]) => {
    let defaultValue = isFileRequiredWithoutOverride(fileTypeId, fileTypes);
    if(requiredFiles.find(x=>x === fileTypeId)) {
        defaultValue = true;
        //console.log('File is default....');
    } else {
        //console.log(':(');
    }
    return isFileRequiredWithOverride(fileTypeId, overrideFileTypes, defaultValue);
}

const isFileRequiredWithoutOverride = (fileTypeId: number, fileTypes: fileType[]) => {
    const matchingFileTypes = fileTypes.filter(ft => fileTypeId === ft.id);
    let isRequired;
    if (matchingFileTypes.length === 0) {
        isRequired = false;
    } else {
        isRequired = matchingFileTypes[0].required;
    }
    return isRequired;
}

export const isFileRequiredWithOverride = (fileTypeId: number, overrideFileTypes: fileTypeRequirementOverride[], defaultValue: boolean): boolean => {
    if (overrideFileTypes === null || overrideFileTypes === undefined) {
        return defaultValue;
    }
    const matchingFileTypeOverrides = overrideFileTypes.filter(ft => fileTypeId === ft.fileTypeId);
    let isRequired;
    if (matchingFileTypeOverrides.length === 0) {
        isRequired = defaultValue;
    } else {
        isRequired = matchingFileTypeOverrides[0].isRequired;
    }
    return isRequired;
}

const getOverrideFileTypes = (fileTypes: fileType[], overrideFileTypes: fileTypeRequirementOverride[], requiredFiles:number[]): fileType[] => {
    return fileTypes.map((ft) => {
        return {
            required: isFileRequiredWithOverride(ft.id, overrideFileTypes, ft.required || requiredFiles.find(x=>x === ft.id) ? true:false),
            id: ft.id,
            name: ft.name,
            // projectId: ft.projectId,
            fileTypeId: ft.fileTypeId,
            fileType: '',
            FreezeDate: ft.FreezeDate,
            FreezeDateString: ft.FreezeDateString,
            
        }
    });
}

export const projectHeader = (project: project) => {
    return (
        <>
            <h3><strong>{project.ProjectId}: </strong> {project.Title}</h3>
            {project.Category.Name}<br />
            <div>Student(s):  {studentsList(project)}</div>
        </>
    )
}

const studentsList = (project: project) => {
    const lastFirstNameStudents = project.TeamMembers.map(m => `${m.firstName} ${m.lastName}`);
    return lastFirstNameStudents.join(", ");
}

export const blobToDataURL = async (blob?: Blob): Promise<string> => {
    if (!blob) {
        return new Promise<string>((accept, reject) => {
            accept("");
        });
    }
    let reader = new FileReader();
    let myPromise = new Promise<string>((accept, reject) => {
        reader.onload = (e) => {
            accept(e.target?.result as string);
        }
    });

    reader.readAsDataURL(blob);
    return myPromise;
}

export enum fileStatusType {
    new = 100,
    needsWork = 200,
    pending = 300,
    unknown = 400,
    accepted = 500,
    noFileFound = 600
}

export const getFileStatusString = (status: fileStatusType | string | undefined) => {
    if (!status) return 'Pending Status...';
    //@ts-ignore
    if (status.toLowerCase) {
        //@ts-ignore
        status = status.toLowerCase()
    }
    switch (status) {
        case 100:
            return "New";
        case 200:
        case "Rejected":
        case "needswork":
            return "Needs Work";
        case 300:
            return "Pending";
        case 400:
            return "Unknown";
        case 500:
            return "Accepted";
        case 600:
            return "No File Found";
    }
    return status;
}

export const getFileStatusIconClass = (fileStatus: fileStatus) => {
    switch (fileStatus) {
        case "Required":
            return "fal fa-file-upload fa-3x";
        case "Rejected":
        case "NeedsWork":
            return "fal fa-file-exclamation fa-3x";
        case "Pending":
            return "fal fa-file-minus fa-3x file-pending";
        case "Accepted":
            return "fal fa-file-check fa-3x file-approved";
            
        default:
            return "fal fa-file fa-3x new-file-upload";
    }
}
export const getPacketStatusIconClass = (fileStatus: fileStatus) => {
    
    switch (fileStatus) {
        case "Required":
            return "fa-sharp-duotone fa-solid fa-file-upload fa-3x";
        case "Rejected":
            return "fa-sharp-duotone fa-solid fa-file-excel fa-3x";
        case "NeedsWork":
            return "fa-sharp-duotone fa-solid fa-file-exclamation fa-3x";
        case "Pending":
            return "fa-sharp-duotone fa-solid fa-file-minus fa-3x";
        case "Accepted":
            return "fa-sharp-duotone fa-solid fa-file-check fa-3x";            
        default:
            return "fa-sharp-duotone fa-solid fa-file fa-3x";
    }
}

export const getFileStatusClass = (fileStatus: fileStatus) => {
    switch (fileStatus) {
        case "Required":
            return "text-muted";
        case "Rejected":
        case "NeedsWork":
            return "text-danger";
        case "Pending":
            return "text-warning";
        case "Accepted":
            return "text-success";
        default:
            return "text-info";
    }
}