import { useRef, useEffect, useState } from "react";
import { IFieldInfo, ISigner } from "../GenericForms/formTypes";
import PdfInput from "./PdfInput";

interface IPdfViewPageProps {
    viewOnly:boolean;
    scale:number;
    inputValueChanged:Function;
    formValues: any;
    //tabToNext:Function;
    fieldsMetaData:Record<string,IFieldInfo>;    
    missingFields:Record<string,IFieldInfo>;    
    signingRequested:Function;
    isSetupMode:boolean;
    signer?:ISigner;
    fieldSelected?:(x:IFieldInfo)=>void;
    //pageAnnotations:any[];
    //tabIndex:number;
    page:any;
    scrollTo:boolean;
}

const PdfViewerPage = (props:IPdfViewPageProps) => {
        const canvasRef = useRef<HTMLCanvasElement>();
        const [page, setPage] = useState<any>();
        const [pageAnnotations, setAnnotations] = useState<any[]>([]);
        const [tabIndex, setTabIndex] = useState<number>(0);

    useEffect(()=>{
        if(props.page){// && !page){
            setPage(props.page);
            console.log('Page Set');
        }
    },[props.page]);

    useEffect(()=>{
        if(props.scrollTo){
            canvasRef?.current.scrollIntoView();
        }
    }, [props.scrollTo]);

    useEffect(()=>{
        
        if(page){
            let viewport = page.getViewport({ scale: props.scale });
          if(viewport.width > 1000){
            let w = viewport.width / props.scale;
            let s = 950 / w;
            viewport = page.getViewport({ scale: s });
          }

          const canvas: any = canvasRef.current;
          if (!canvas) {
            return;
          }
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          const renderContext = {
            canvasContext: canvas.getContext("2d"),
            viewport: viewport,
          };
          page.render(renderContext);
          page.getAnnotations().then((items) => {
            setAnnotations(items);
            console.log(items);
          });
        }
    },[page]);

    const tabToNext = (index) => {
        for (let i = index + 1; i < pageAnnotations.length; i++) {
          let a = pageAnnotations[i];
          //if (a.hasAppearance && a.fieldType === "Tx") {
          if (a.fieldType === "Tx") {
            setTabIndex(i);
            return;
          }
        }
        // if(pdfRef.numPages > currentPage){
        //   setTabIndex(0);
        //   setCurrentPage(currentPage+1);
        // }
      };

    return (<>
        <div className="zpdf-page">
            <canvas className="zpdf-viewer-canvas" ref={canvasRef} />
            {!props.viewOnly && pageAnnotations.length > 0 &&
                pageAnnotations
                  //.filter((x) => x.hasAppearance && x.fieldType)
                  .filter((x) => x.fieldType)
                  .map((x, i) => {
                    let active = i === tabIndex;
                    return (
                      <PdfInput
                        index={i}
                        tabToNext={tabToNext}
                        hasFocus={active}
                        position={x.rect}
                        value={x.fieldValue}
                        exportValue={x.exportValue}
                        type={x.fieldType}
                        name={x.fieldName}
                        id={x.fieldName}
                        scale={props.scale}
                        valueUpdated={props.inputValueChanged}
                        values={props.formValues}
                        key={`page-input-${i}`}
                        fieldMetaData={props.fieldsMetaData[x.fieldName]}
                        canEdit={true}
                        signer={props.signer}       
                        requestSign={props.signingRequested}                 
                        isSetupMode={props.isSetupMode}
                        missingFields={props.missingFields}
                        fieldSelected={()=>{
                          console.log('Viewer Field Selected', props);
                          if(props.fieldSelected) props.fieldSelected(x);
                        }}
                      />
                    );
                  })}
        </div>
    </>);
}

export default PdfViewerPage;