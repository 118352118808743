import { useCallback, useEffect, useRef, useState } from 'react';
import StoreX from '../../redux/oldStore';
import * as pdfjsLib from "pdfjs-dist";

interface IProps {
  dataUrl?: string;
  scale?:number;
}

const PdfBasicViewer = (props: IProps) => {
  
  pdfjsLib.GlobalWorkerOptions.workerSrc =
    "https://unpkg.com/pdfjs-dist@4.9.155/build/pdf.worker.min.mjs";

  const [page, setPage] = useState<any>();
  const [pages, setPages] = useState<any[]>([]);
  const [pageNo, setPageNo] = useState<number>(0);
  const [pdfRef, setPdfRef] = useState<any>();

  const canvasRef = useRef(null);

  useEffect(() => {
    //const fullUrl = StoreX.BuildUrl(props.source ?? "");
    const loadingTask = pdfjsLib.getDocument(props.dataUrl);
    loadingTask.promise.then(
      (loadedPdf) => {
        //console.log('Pdf Loaded');
        setPdfRef(loadedPdf);
      },
      (reason) => {
        console.error("Failed to load Pdf in PdfBasicViewer",reason);
      }
    );
  }, [props.dataUrl]);

  useEffect(() => {
    renderPage(pageNo, pdfRef);
  }, [pdfRef]);

  useEffect(() => {
    if(pages && pageNo > 0){
        let p = pages[pageNo - 1];
        setPage(p);
        //console.log('Page Set', pageNo, pages, p);
    }
  }, [pageNo, pages]);

  const renderPage = useCallback(
    async (pageNum, pdf = pdfRef) => {
      if (pdf) {
        let xPages: any[] = [];
        for (let i = 0; i < pdf.numPages; i++) {
          await pdf.getPage(i + 1).then(function (page) {
            if (i === 0) setPages([page]);
            xPages.push(page);
          });
        }
        setPages(xPages);
        setPageNo(1);
        //console.log('Pdf Pages Set');
      }
    },
    [pdfRef]
  );

  useEffect(() => {
    //console.log('...page...')
    if (page) {
        //console.log('Drawing page...', page);
      let viewport = page.getViewport({ scale: props.scale ?? 1 });
      if (viewport.width > 1000) {
        let w = viewport.width / (props.scale ?? 1);
        let s = 950 / w;
        viewport = page.getViewport({ scale: s });
      }

      const canvas: any = canvasRef.current;
      if (!canvas) {
        return;
      }
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      const renderContext = {
        canvasContext: canvas.getContext('2d'),
        viewport: viewport,
      };
      page.render(renderContext);
    }
  }, [page]);

  return (
    <>
      <div className="pdf-basic-viewer">
        <canvas ref={canvasRef} />
        <div>
            Pages: {pageNo} of {pages.length}
        </div>
      </div>
    </>
  );
};

export default PdfBasicViewer;
